var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import styles from './ApprovedChecklistItemTables.module.scss';
import { observer } from 'mobx-react';
import { Column } from 'devextreme-react/data-grid';
import { FunctionList } from '@app/Components/Grid/FunctionList/FunctionList';
import { DocumentStatusIcon } from '@app/Components/DocumentStatusIcon/DocumentStatusIcon';
import HTMLReactParser from 'html-react-parser';
import columnSharedStyles from '@app/Components/Grid/SharedStyles/column-shared-styles.module.scss';
import { EditCell } from '@app/Components/Grid/EditCell/EditCell';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import { ApprovalApplicationEnum, ChecklistSections, ObjectType } from '@app/AppConstants';
import { action, computed, observable } from 'mobx';
import { Api } from '@app/Api';
import { Grid } from '@app/Components/Grid/Grid';
import { CodeCell } from '@app/Components/Grid/CodeCell/CodeCell';
import { ChangeLogDialog } from '@app/Components/Dialogs/ChangeLogDialog';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { DocumentView } from '@app/Widgets/DocumentView/DocumentView';
import { CommentsList } from '@app/Components/Grid/CommentsList/CommentsList';
import { ApprovedChecklistView } from '@app/Pages/ProjectView/ProjectViewTabs/GatePreparations/ApprovedGateView/ApprovedChecklistView/ApprovedChecklistView';
import { RatingCell } from '@app/Components/Grid/RatingCell/RatingCell';
import { DocumentButton } from '@app/Components/DocumentButton/DocumentButton';
import { Modal } from '@app/Components/Modal/Modal';
import { GlobalLoaderWrapper } from '@app/Components/Loader/GlobalLoader/GlobalLoaderWrapper';
var ApprovedChecklistItemMasterDeliverableTablePartC = /** @class */ (function (_super) {
    __extends(ApprovedChecklistItemMasterDeliverableTablePartC, _super);
    function ApprovedChecklistItemMasterDeliverableTablePartC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._loadingDelivers = false;
        _this._loadingChecklists = false;
        _this._isLogVisible = false;
        _this._delivers = [];
        _this._checklists = [];
        _this._documentViewSection = ChecklistSections.ProjectManager;
        return _this;
    }
    ApprovedChecklistItemMasterDeliverableTablePartC.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadDelivers()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadChecklists()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistItemMasterDeliverableTablePartC.prototype._loadDelivers = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        this._loadingDelivers = true;
                        return [4 /*yield*/, Api.secured.getGateAssessmentKeyDeliverable({
                                checklistItemId: this.props.checkListItemId
                            })];
                    case 1:
                        result = _a.sent();
                        this._delivers = [result];
                        return [3 /*break*/, 3];
                    case 2:
                        this._loadingDelivers = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistItemMasterDeliverableTablePartC.prototype._toggleLogModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._isLogVisible = !this._isLogVisible;
                return [2 /*return*/];
            });
        });
    };
    Object.defineProperty(ApprovedChecklistItemMasterDeliverableTablePartC.prototype, "currentDeliverable", {
        get: function () {
            if (this._delivers && this._delivers.length === 1)
                return this._delivers[0];
            return null;
        },
        enumerable: false,
        configurable: true
    });
    ApprovedChecklistItemMasterDeliverableTablePartC.prototype._saveChecklistItem = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.updateCLIDAssessmentRelatedManualTaskWorkerPart({
                            manualTaskId: this.props.manualTaskId,
                            body: rowData
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistItemMasterDeliverableTablePartC.prototype._handleDocumentClick = function (ciid, section) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._documentViewSection = section;
                this.props.history.push("".concat(this.props.match.url, "/upload/cl-").concat(ciid));
                return [2 /*return*/];
            });
        });
    };
    ApprovedChecklistItemMasterDeliverableTablePartC.prototype._savePMDeliverable = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.updateCLIDAssessmentRelatedManualTaskWorkerPart({
                            body: rowData,
                            manualTaskId: this.props.manualTaskId
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadDelivers()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistItemMasterDeliverableTablePartC.prototype.onFileChange = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadDelivers()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistItemMasterDeliverableTablePartC.prototype._loadChecklists = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, , 2, 3]);
                        this._loadingChecklists = true;
                        _a = this;
                        return [4 /*yield*/, Api.secured.getFunctionalOnlyChecklists({
                                projectId: this.props.projectId,
                                gateId: this.props.gateId
                            })];
                    case 1:
                        _a._checklists = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this._loadingChecklists = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistItemMasterDeliverableTablePartC.prototype._toggleChecklistsModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, history, match;
            return __generator(this, function (_b) {
                _a = this.props, history = _a.history, match = _a.match;
                history.push(match.url);
                return [2 /*return*/];
            });
        });
    };
    ApprovedChecklistItemMasterDeliverableTablePartC.prototype._handleChecklistChanged = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadChecklists()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadDelivers()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistItemMasterDeliverableTablePartC.prototype.render = function () {
        var _this = this;
        var _a = this.props, canPrincipalUpdateWorkerPart = _a.canPrincipalUpdateWorkerPart, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { expandableRows: true, dataSource: __spreadArray([], this._delivers, true), rowsCount: this._delivers.length, loading: this._loadingDelivers || this._loadingChecklists },
                React.createElement(Column, { caption: "ID", dataField: "code", width: 100, allowEditing: false, cssClass: columnSharedStyles.colWithHistory, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(React.Fragment, null,
                            React.createElement(CodeCell, { code: data.code, onHistoryClick: function () { return (_this._isLogVisible = true); } }),
                            data.masterDeliverable &&
                                data.masterDeliverable.stateAtGateAssessment &&
                                data.masterDeliverable.currentStatus && (React.createElement(DocumentStatusIcon, { targetStatus: data.masterDeliverable.stateAtGateAssessment, isFileUploaded: data.masterDeliverable.hasFile, isCommented: data.masterDeliverable.hasComment, isRated: data.masterDeliverable.hasRating, currentStatus: data.masterDeliverable.currentStatus, deliverableId: data.id, isExternalManagement: data.masterDeliverable.approvalApplication !==
                                    ApprovalApplicationEnum.TeamGate, isNotApplicable: data.masterDeliverable.isApprovalDisabled ||
                                    data.masterDeliverable.isNotApplicableAfterApproval, rating: data.masterDeliverable.rating }))));
                    } }),
                React.createElement(Column, { caption: "Functions", width: 90, allowEditing: false, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(FunctionList, { functions: data.functions, rowId: '' + data.id }));
                    } }),
                React.createElement(Column, { caption: "Key Deliverables", dataField: "keyDeliverables", width: 250, allowEditing: false }),
                React.createElement(Column, { caption: "Details", dataField: "guideline", minWidth: 250, allowEditing: false, cellRender: function (_a) {
                        var data = _a.data;
                        return React.createElement("div", null, HTMLReactParser(data.guideline));
                    } }),
                React.createElement(Column, { caption: "Project Manager Comments", dataField: "projectManagerOriginalComment", width: 300, cssClass: columnSharedStyles.commentColumn, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(EditCell, { value: data.projectManagerOriginalComment, onChange: function (value) { return (data.projectManagerOriginalComment = value); }, onSave: function () { return _this._saveChecklistItem(data); }, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), withCopy: true, disabled: !canPrincipalUpdateWorkerPart || !data.isGateEditable, section: ChecklistSections.ProjectManager },
                            React.createElement(CommentsList, { comments: data.projectManagerComments })));
                    } }),
                React.createElement(Column, { headerCellRender: function () { return React.createElement("div", { title: "Project Manager Rating" }, "Rating"); }, width: 100, cssClass: styles.ratingColumn, cellRender: function (_a) {
                        var _b;
                        var data = _a.data;
                        return (React.createElement("section", null,
                            React.createElement("div", { className: styles.ratingCellWrapper },
                                React.createElement("div", { className: styles.ratingWrapper },
                                    React.createElement(RatingCell, { onSave: function () { return _this._savePMDeliverable(data); }, onChange: function (value) { return (data.projectManagerOriginalRating = value); }, rating: data.projectManagerRating, showValidationError: false, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), disabled: !canPrincipalUpdateWorkerPart || !data.isGateEditable, section: ChecklistSections.ProjectManager, controlId: "pmRating".concat(data.id) }))),
                            React.createElement("div", { className: styles.commentActions },
                                React.createElement("div", { className: "".concat(styles.commentAction, " ").concat(styles.documentIconWrapper) }, data.isDocumentUploadVisible && data.canDocumentBeUploadByPjm && (React.createElement("div", { className: styles.commentActions },
                                    React.createElement("div", { className: "".concat(styles.commentAction, " ").concat(styles.documentIconWrapper) },
                                        React.createElement(DocumentButton, { hasDocument: data.hasDocuments, onDocumentIconClick: function () {
                                                return _this._handleDocumentClick(data.id, ChecklistSections.ProjectManager);
                                            }, className: styles.documentIconButton, hasRedDot: (_b = data.masterDeliverable) === null || _b === void 0 ? void 0 : _b.hasRedDot }))))))));
                    } })),
            this._isLogVisible && this.currentDeliverable && (React.createElement(ChangeLogDialog, { onToggle: this._toggleLogModal, uniqueId: createUniqueId(ObjectType.ChecklistItem, this.currentDeliverable.id) })),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "".concat(this.props.match.url, "/upload/cl-:id") },
                    React.createElement(DocumentView, { backUrl: this.props.match.url, section: this._documentViewSection, onFileChange: this.onFileChange, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted }))),
            React.createElement(Switch, null, this._checklists.map(function (c) { return (React.createElement(Route, { key: c.id, path: "".concat(_this.props.match.url, "/cl-").concat(c.id) },
                !c.isEnabled && React.createElement(Redirect, { to: _this.props.match.url }),
                React.createElement(Modal, { isOpen: true, toggle: _this._toggleChecklistsModal, headerTitle: "Checklist Info", maxWidth: "95%" },
                    React.createElement(GlobalLoaderWrapper, null,
                        React.createElement(ApprovedChecklistView, { checklist: c, gateId: _this.props.gateId, gateCodeName: _this.props.gateDisplayName, projectId: c.projectId, projectCodeName: c.projectCode, onChecklistChanged: _this._handleChecklistChanged, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted }))))); }))));
    };
    __decorate([
        observable
    ], ApprovedChecklistItemMasterDeliverableTablePartC.prototype, "_loadingDelivers", void 0);
    __decorate([
        observable
    ], ApprovedChecklistItemMasterDeliverableTablePartC.prototype, "_loadingChecklists", void 0);
    __decorate([
        observable
    ], ApprovedChecklistItemMasterDeliverableTablePartC.prototype, "_isLogVisible", void 0);
    __decorate([
        observable.shallow
    ], ApprovedChecklistItemMasterDeliverableTablePartC.prototype, "_delivers", void 0);
    __decorate([
        observable
    ], ApprovedChecklistItemMasterDeliverableTablePartC.prototype, "_checklists", void 0);
    __decorate([
        action.bound
    ], ApprovedChecklistItemMasterDeliverableTablePartC.prototype, "_loadDelivers", null);
    __decorate([
        action.bound
    ], ApprovedChecklistItemMasterDeliverableTablePartC.prototype, "_toggleLogModal", null);
    __decorate([
        computed
    ], ApprovedChecklistItemMasterDeliverableTablePartC.prototype, "currentDeliverable", null);
    __decorate([
        action.bound
    ], ApprovedChecklistItemMasterDeliverableTablePartC.prototype, "_saveChecklistItem", null);
    __decorate([
        action.bound
    ], ApprovedChecklistItemMasterDeliverableTablePartC.prototype, "_handleDocumentClick", null);
    __decorate([
        action.bound
    ], ApprovedChecklistItemMasterDeliverableTablePartC.prototype, "_savePMDeliverable", null);
    __decorate([
        action.bound
    ], ApprovedChecklistItemMasterDeliverableTablePartC.prototype, "onFileChange", null);
    __decorate([
        action.bound
    ], ApprovedChecklistItemMasterDeliverableTablePartC.prototype, "_loadChecklists", null);
    __decorate([
        action.bound
    ], ApprovedChecklistItemMasterDeliverableTablePartC.prototype, "_toggleChecklistsModal", null);
    __decorate([
        action.bound
    ], ApprovedChecklistItemMasterDeliverableTablePartC.prototype, "_handleChecklistChanged", null);
    ApprovedChecklistItemMasterDeliverableTablePartC = __decorate([
        observer
    ], ApprovedChecklistItemMasterDeliverableTablePartC);
    return ApprovedChecklistItemMasterDeliverableTablePartC;
}(React.Component));
export var ApprovedChecklistItemMasterDeliverableTablePart = withRouter(ApprovedChecklistItemMasterDeliverableTablePartC);
