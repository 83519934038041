/* tslint:disable:max-classes-per-file max-file-line-count */
export var ApiUrls = /** @class */ (function () {
    function ApiUrls() {
    }
    // ReSharper disable InconsistentNaming
    ApiUrls.ErrorUrl = '/api/error';
    ApiUrls.RootUrl = '/api';
    ApiUrls.PersonUrl = '/api/person';
    ApiUrls.JobUrl = '/api/job/';
    ApiUrls.JobList = '/api/job/list';
    ApiUrls.JobStartUrl = '/api/job/start';
    ApiUrls.JobParametersUrl = '/api/job/parameters';
    ApiUrls.JobTriggerCreateUrl = '/api/job/create';
    ApiUrls.JobTriggerDeleteUrl = '/api/job/delete';
    ApiUrls.ConfigurationUrl = '/api/configuration';
    return ApiUrls;
}());
export var ComponentUrls = /** @class */ (function () {
    function ComponentUrls() {
    }
    ComponentUrls.HubUrl = '/hubs/notification';
    ComponentUrls.HubImpersonatedPersonIdQueryString = '?impersonatedPrincipalId=';
    ComponentUrls.DocumentUrl = '/document/';
    return ComponentUrls;
}());
export var DateTime = /** @class */ (function () {
    function DateTime() {
    }
    DateTime.jsonDateFormat = 'YYYY-MM-DD';
    DateTime.viewDateFormat = 'DD.MM.YYYY';
    DateTime.jsonFullFormat = 'YYYY-MM-DD HH:mm:ss';
    DateTime.jsonDateTimeFormat = 'YYYY-MM-DDTHH:mm:ssZ';
    DateTime.viewFullFormat = 'DD.MM.YYYY HH:mm';
    DateTime.momentFullFormat = 'DD.MM.YYYY HH:mm:ss';
    DateTime.viewMonthFormat = 'MM.YYYY';
    DateTime.timeFormat = 'HH:mm';
    DateTime.minDate = '01.01.1970';
    DateTime.dayMonthFormat = 'DD.MM';
    DateTime.tcDayFormat = 'dd';
    DateTime.ocDayFormat = 'D';
    DateTime.fullMonthFullYearDateFormat = 'MMMM YYYY';
    return DateTime;
}());
export var Status = /** @class */ (function () {
    function Status() {
    }
    Status.Draft = 'Draft';
    Status.InApproval = 'InApproval';
    Status.Approved = 'Approved';
    return Status;
}());
export var ParameterType = /** @class */ (function () {
    function ParameterType() {
    }
    ParameterType.DateTime = 'DateTime';
    ParameterType.Date = 'Date';
    ParameterType.Boolean = 'Boolean';
    ParameterType.MultiLine = 'Multiline';
    ParameterType.SingleLine = 'String';
    ParameterType.Integer = 'Integer';
    ParameterType.Enum = 'Enum';
    return ParameterType;
}());
export var HttpStatusCode;
(function (HttpStatusCode) {
    HttpStatusCode[HttpStatusCode["BadRequest"] = 400] = "BadRequest";
    HttpStatusCode[HttpStatusCode["Conflict"] = 409] = "Conflict";
})(HttpStatusCode || (HttpStatusCode = {}));
export var SignalREvents = /** @class */ (function () {
    function SignalREvents() {
    }
    SignalREvents.success = 'success';
    SignalREvents.warning = 'warning';
    SignalREvents.info = 'info';
    SignalREvents.error = 'error';
    SignalREvents.jobStatusChanged = 'jobStatusChanged';
    SignalREvents.jobProgressMaxValueChanged = 'jobProgressMaxValueChanged';
    SignalREvents.jobProgressValueChanged = 'jobProgressValueChanged';
    SignalREvents.jobFinished = 'jobFinished';
    SignalREvents.excelImportProgressValueChanged = 'excelImportProgressValueChanged';
    SignalREvents.getConnectionId = 'GetConnectionId';
    SignalREvents.cancelEditing = 'CancelEditing';
    SignalREvents.refreshNotifications = 'refreshNotifications';
    SignalREvents.refreshAssignments = 'refreshAssignments';
    SignalREvents.notifyReleasedObject = 'notifyReleasedObject';
    SignalREvents.notifyLockedObject = 'notifyLockedObject';
    SignalREvents.actionItemsChanged = 'actionItemsChanged';
    SignalREvents.recommendationChanged = 'recommendationChanged';
    SignalREvents.decisionChanged = 'decisionChanged';
    SignalREvents.checklistStatusChanged = 'checklistStatusChanged';
    SignalREvents.deliverableRatingsChanged = 'deliverableRatingsChanged';
    SignalREvents.sendJobTimeline = 'sendJobTimeline';
    return SignalREvents;
}());
export var Events = /** @class */ (function () {
    function Events() {
    }
    Events.deliverablesConfirm = 'deliverablesConfirm';
    Events.questionsConfirm = 'questionsConfirm';
    Events.assessmentGateDeliversConfirm = 'assessmentGateDeliversConfirm';
    Events.assessmentGateFunctionSummariesConfirm = 'assessmentGateFunctionSummariesConfirm';
    Events.assessmentGateExecutiveQuestionsConfirm = 'assessmentGateExecutiveQuestionsConfirm';
    Events.meetingGateDeliversConfirm = 'meetingGateDeliversConfirm';
    Events.meetingGateFunctionSummariesConfirm = 'meetingGateFunctionSummariesConfirm';
    Events.meetingGateExecutiveQuestionsConfirm = 'meetingGateExecutiveQuestionsConfirm';
    Events.setPassedGateYearTogglerTrue = 'setPassedGateYearTogglerTrue';
    Events.setIncludeSkippedTogglerTrue = 'setIncludeSkippedTogglerTrue';
    return Events;
}());
export var ProjectGates = /** @class */ (function () {
    function ProjectGates() {
    }
    ProjectGates.gateDefaultLengthInWeeks = 13;
    return ProjectGates;
}());
export var ProjectState;
(function (ProjectState) {
    ProjectState["IsEmpty"] = "IsEmpty";
    ProjectState["HasOnlyMasterCheckLists"] = "HasOnlyMasterCheckLists";
    ProjectState["Full"] = "Full";
    ProjectState["Milestone"] = "Milestone";
})(ProjectState || (ProjectState = {}));
export var ProjectStatus;
(function (ProjectStatus) {
    ProjectStatus["Draft"] = "Draft";
    ProjectStatus["InApproval"] = "InApproval";
    ProjectStatus["Approved"] = "Approved";
    ProjectStatus["Completed"] = "Completed";
    ProjectStatus["Withdrawn"] = "Withdrawn";
    ProjectStatus["Terminated"] = "Terminated";
})(ProjectStatus || (ProjectStatus = {}));
export var StatusEnum;
(function (StatusEnum) {
    StatusEnum["Draft"] = "Draft";
    StatusEnum["InApproval"] = "InApproval";
    StatusEnum["Approved"] = "Approved";
    StatusEnum["NotApplicable"] = "NotApplicable";
})(StatusEnum || (StatusEnum = {}));
export var CheckListItemRatingEnum;
(function (CheckListItemRatingEnum) {
    CheckListItemRatingEnum["Default"] = "Default";
    CheckListItemRatingEnum["Ok"] = "Ok";
    CheckListItemRatingEnum["OnGoing"] = "OnGoing";
    CheckListItemRatingEnum["NotOk"] = "NotOk";
    CheckListItemRatingEnum["NotApplicable"] = "NotApplicable";
})(CheckListItemRatingEnum || (CheckListItemRatingEnum = {}));
export var LockStatusEnum;
(function (LockStatusEnum) {
    LockStatusEnum["FailedToLock"] = "FailedToLock";
    LockStatusEnum["Locked"] = "Locked";
    LockStatusEnum["AlreadyLocked"] = "AlreadyLocked";
})(LockStatusEnum || (LockStatusEnum = {}));
export var ManualTaskStatusEnum;
(function (ManualTaskStatusEnum) {
    ManualTaskStatusEnum["ProjectManagement"] = "ProjectManagement";
    ManualTaskStatusEnum["DelegateDeliverable"] = "DelegateDeliverable";
    ManualTaskStatusEnum["DelegateAssessmentDeliverable"] = "DelegateAssessmentDeliverable";
    ManualTaskStatusEnum["DelegateQuestion"] = "DelegateQuestion";
    ManualTaskStatusEnum["DelegateMilestoneMasterCliDeliverable"] = "DelegateMilestoneMasterCliDeliverable";
    ManualTaskStatusEnum["DelegateMasterExecutiveQuestion"] = "DelegateMasterExecutiveQuestion";
    ManualTaskStatusEnum["DelegateMilestoneMasterExecutiveQuestion"] = "DelegateMilestoneMasterExecutiveQuestion";
})(ManualTaskStatusEnum || (ManualTaskStatusEnum = {}));
export var ManualTaskStateEnum;
(function (ManualTaskStateEnum) {
    ManualTaskStateEnum["Open"] = "Open";
    ManualTaskStateEnum["CantDo"] = "CantDo";
    ManualTaskStateEnum["Done"] = "Done";
    ManualTaskStateEnum["Deleted"] = "Deleted";
    ManualTaskStateEnum["ClosedBySystem"] = "ClosedBySystem";
})(ManualTaskStateEnum || (ManualTaskStateEnum = {}));
export var ObjectType = /** @class */ (function () {
    function ObjectType() {
    }
    ObjectType.Checklist = 'CHL';
    ObjectType.ChecklistItem = 'CLI';
    ObjectType.FileInformation = 'FLI';
    ObjectType.ActionItem = 'ACI';
    ObjectType.ChecklistItemDeliverable = 'DLV';
    ObjectType.Project = 'PRJ';
    ObjectType.Gate = 'GTE';
    ObjectType.GateMeeting = 'GTM';
    ObjectType.GateRecommendation = 'GTR';
    ObjectType.NorrdProject = 'NPR';
    ObjectType.Meeting = 'MTG';
    ObjectType.ManualTask = 'TSK';
    ObjectType.RelatedFunction = 'RLF';
    ObjectType.Approval = 'APR';
    ObjectType.DocumentCategory = 'DCT';
    ObjectType.ProjectDocument = 'PRD';
    ObjectType.ProjectDocumentFile = 'PDF';
    return ObjectType;
}());
export var ChecklistSections = /** @class */ (function () {
    function ChecklistSections() {
    }
    ChecklistSections.Function = 'Function';
    ChecklistSections.Assessor = 'Assessor';
    ChecklistSections.AssessorMeeting = 'Assessor Meeting';
    ChecklistSections.ProjectManager = 'Project Manager';
    ChecklistSections.DenyComment = 'Deny Comments';
    ChecklistSections.DeliverableFileComments = 'Deliverable File Comments';
    return ChecklistSections;
}());
export var ActionItemDeadLineEnum;
(function (ActionItemDeadLineEnum) {
    ActionItemDeadLineEnum["Asap"] = "Asap";
    ActionItemDeadLineEnum["BeforeNextGate"] = "BeforeNextGate";
    ActionItemDeadLineEnum["ByDate"] = "ByDate";
})(ActionItemDeadLineEnum || (ActionItemDeadLineEnum = {}));
export var GateRecommendationDecisionEnum;
(function (GateRecommendationDecisionEnum) {
    GateRecommendationDecisionEnum["NotSpecified"] = "NotSpecified";
    GateRecommendationDecisionEnum["Go"] = "Go";
    GateRecommendationDecisionEnum["GoWithActionItem"] = "GoWithActionItem";
    GateRecommendationDecisionEnum["RedoGate"] = "RedoGate";
    GateRecommendationDecisionEnum["CloseTerminate"] = "CloseTerminate";
})(GateRecommendationDecisionEnum || (GateRecommendationDecisionEnum = {}));
export var GateProcessTypeEnum;
(function (GateProcessTypeEnum) {
    GateProcessTypeEnum["GateAssessment"] = "GateAssessment";
    GateProcessTypeEnum["GateMeeting"] = "GateMeeting";
})(GateProcessTypeEnum || (GateProcessTypeEnum = {}));
export var ChecklistTypeEnum;
(function (ChecklistTypeEnum) {
    ChecklistTypeEnum["Function"] = "Function";
    ChecklistTypeEnum["Master"] = "Master";
})(ChecklistTypeEnum || (ChecklistTypeEnum = {}));
export var ProjectTemplateTypeEnum;
(function (ProjectTemplateTypeEnum) {
    ProjectTemplateTypeEnum["Functional"] = "Functional";
    ProjectTemplateTypeEnum["Master"] = "Master";
})(ProjectTemplateTypeEnum || (ProjectTemplateTypeEnum = {}));
export var ChecklistItemType;
(function (ChecklistItemType) {
    ChecklistItemType["FunctionDeliverable"] = "FunctionDeliverable";
    ChecklistItemType["FunctionQuestion"] = "FunctionQuestion";
    ChecklistItemType["MasterDeliverable"] = "MasterDeliverable";
    ChecklistItemType["MasterFunctionSummary"] = "MasterFunctionSummary";
    ChecklistItemType["MasterExecutiveQuestion"] = "MasterExecutiveQuestion";
    ChecklistItemType["MasterQuestion"] = "MasterQuestion";
    ChecklistItemType["MasterMainFunctionSummary"] = "MasterMainFunctionSummary";
    ChecklistItemType["MasterDeliverableWithoutFunctionDeliverable"] = "MasterDeliverableWithoutFunctionDeliverable";
})(ChecklistItemType || (ChecklistItemType = {}));
export var SkipReasonEnum;
(function (SkipReasonEnum) {
    SkipReasonEnum["NorrdCompleted"] = "NorrdCompleted";
    SkipReasonEnum["Manual"] = "Manual";
})(SkipReasonEnum || (SkipReasonEnum = {}));
export var GateProcessStage;
(function (GateProcessStage) {
    GateProcessStage["Setup"] = "Setup";
    GateProcessStage["PreparationAssessment"] = "PreparationAssessment";
    GateProcessStage["Meeting"] = "Meeting";
    GateProcessStage["Closed"] = "Closed";
    GateProcessStage["OnHoldRequested"] = "OnHoldRequested";
    GateProcessStage["OnHold"] = "OnHold";
    GateProcessStage["UndoOnHoldRequested"] = "UndoOnHoldRequested";
})(GateProcessStage || (GateProcessStage = {}));
export var ApprovalResultEnum;
(function (ApprovalResultEnum) {
    ApprovalResultEnum["NotYetSet"] = "NotYetSet";
    ApprovalResultEnum["Approved"] = "Approved";
    ApprovalResultEnum["Denied"] = "Denied";
})(ApprovalResultEnum || (ApprovalResultEnum = {}));
export var ApprovalApplicationEnum;
(function (ApprovalApplicationEnum) {
    ApprovalApplicationEnum["TeamGate"] = "TeamGate";
    ApprovalApplicationEnum["TMS"] = "TMS";
    ApprovalApplicationEnum["MFiles"] = "MFiles";
})(ApprovalApplicationEnum || (ApprovalApplicationEnum = {}));
export var ApprovalTypeEnum;
(function (ApprovalTypeEnum) {
    ApprovalTypeEnum["ProvideDeliverableTask"] = "ProvideDeliverableTask";
    ApprovalTypeEnum["DraftDeliverableTask"] = "DraftDeliverableTask";
    ApprovalTypeEnum["ApprovalDeliverableTask"] = "ApprovalDeliverableTask";
    ApprovalTypeEnum["MoveDeliverableToInApprovalTask"] = "MoveDeliverableToInApprovalTask";
    ApprovalTypeEnum["MoveFunctionChecklistToInApprovalTask"] = "MoveFunctionChecklistToInApprovalTask";
    ApprovalTypeEnum["ApprovalFunctionChecklistTask"] = "ApprovalFunctionChecklistTask";
})(ApprovalTypeEnum || (ApprovalTypeEnum = {}));
export var ProjectGateModelEnum;
(function (ProjectGateModelEnum) {
    ProjectGateModelEnum["NotRequired"] = "NotRequired";
    ProjectGateModelEnum["OnProjectLevel"] = "OnProjectLevel";
})(ProjectGateModelEnum || (ProjectGateModelEnum = {}));
export var ProjectSecurityLevelEnum;
(function (ProjectSecurityLevelEnum) {
    ProjectSecurityLevelEnum["Internal"] = "Internal";
    ProjectSecurityLevelEnum["Confidential"] = "Confidential";
    ProjectSecurityLevelEnum["StrictlyConfidential"] = "StrictlyConfidential";
})(ProjectSecurityLevelEnum || (ProjectSecurityLevelEnum = {}));
export var ProjectCategoryEnum;
(function (ProjectCategoryEnum) {
    ProjectCategoryEnum["NewGenerationProduct"] = "NewGenerationProduct";
    ProjectCategoryEnum["NextGenerationProduct"] = "NextGenerationProduct";
    ProjectCategoryEnum["ProductEnhancement"] = "ProductEnhancement";
    ProjectCategoryEnum["ProductMaintenance"] = "ProductMaintenance";
    ProjectCategoryEnum["TechnologyDevelopment"] = "TechnologyDevelopment";
    ProjectCategoryEnum["PreStudiesAndScouting"] = "PreStudiesAndScouting";
    ProjectCategoryEnum["RAndDInfrastructure"] = "RAndDInfrastructure";
})(ProjectCategoryEnum || (ProjectCategoryEnum = {}));
export var AssignmentStateEnum;
(function (AssignmentStateEnum) {
    AssignmentStateEnum["Inactive"] = "Inactive";
    AssignmentStateEnum["Active"] = "Active";
})(AssignmentStateEnum || (AssignmentStateEnum = {}));
export var SeparatedStrings = /** @class */ (function () {
    function SeparatedStrings() {
    }
    SeparatedStrings.ApprovalResultNotYetSet = 'Not Yet Set';
    SeparatedStrings.ApprovalApplicationTeamGate = 'Team Gate';
    SeparatedStrings.ApprovalApplicationMFiles = 'M-Files';
    SeparatedStrings.GateProcessStagePreparationAssessment = 'Preparation/Assessment';
    SeparatedStrings.GateProcessStageOnHold = 'On hold';
    SeparatedStrings.GateProcessStageSkippedPostfix = '(Skipped)';
    SeparatedStrings.NorrdProjectGateModelNotRequired = 'Not Required';
    SeparatedStrings.NorrdProjectGateModelOnProjectLevel = 'On Project Level';
    SeparatedStrings.StatusNotApplicable = 'Not Applicable';
    SeparatedStrings.ApprovalTypeProvideDeliverableTask = 'Provide Deliverable Task';
    SeparatedStrings.ApprovalTypeDraftDeliverableTask = 'Draft Deliverable Task';
    SeparatedStrings.ApprovalTypeApprovalDeliverableTask = 'Approval Deliverable Task';
    SeparatedStrings.ApprovalTypeMoveDeliverableToInApprovalTask = 'Move Deliverable To InApproval Task';
    SeparatedStrings.ApprovalTypeMoveFunctionChecklistToInApprovalTask = 'Move Function Checklist To InApproval Task';
    SeparatedStrings.ApprovalTypeApprovalFunctionChecklistTask = 'Approval Function Checklist Task';
    SeparatedStrings.NotSpecified = 'Not Specified';
    SeparatedStrings.GoWithActionItem = 'Go With Action Item';
    SeparatedStrings.RedoGate = 'Redo';
    SeparatedStrings.CloseTerminate = 'Close/Terminate';
    SeparatedStrings.ManualTaskCantDoState = "Can't do";
    SeparatedStrings.ManualTaskClosedBySystemState = 'Closed by system';
    SeparatedStrings.ProjectSecurityLevelStrictlyConfidential = 'Strictly Confidential';
    SeparatedStrings.ProjectCategoryNewGenerationProduct = 'New Product';
    SeparatedStrings.ProjectCategoryNextGenerationProduct = 'Next Generation Product';
    SeparatedStrings.ProjectCategoryProductEnhancement = 'Product Enhancement';
    SeparatedStrings.ProjectCategoryProductMaintenance = 'Product Maintenance';
    SeparatedStrings.ProjectCategoryTechnologyDevelopment = 'Technology Development';
    SeparatedStrings.ProjectCategoryPreStudiesAndScouting = 'Pre-studies and scouting';
    SeparatedStrings.ProjectCategoryRAndDInfrastructure = 'R&D Infrastructure';
    return SeparatedStrings;
}());
export var ApprovalTaskTypeEnum;
(function (ApprovalTaskTypeEnum) {
    ApprovalTaskTypeEnum["FunctionChecklist"] = "FunctionChecklist";
    ApprovalTaskTypeEnum["Deliverable"] = "Deliverable";
})(ApprovalTaskTypeEnum || (ApprovalTaskTypeEnum = {}));
export var GateAssessmentRoleTypeEnum;
(function (GateAssessmentRoleTypeEnum) {
    GateAssessmentRoleTypeEnum["Function"] = "Function";
    GateAssessmentRoleTypeEnum["ProjectManager"] = "ProjectManager";
    GateAssessmentRoleTypeEnum["GateAssessor"] = "GateAssessor";
})(GateAssessmentRoleTypeEnum || (GateAssessmentRoleTypeEnum = {}));
export var GateMeetingRoleTypeEnum;
(function (GateMeetingRoleTypeEnum) {
    GateMeetingRoleTypeEnum["ProjectManager"] = "ProjectManager";
    GateMeetingRoleTypeEnum["GateAssessor"] = "GateAssessor";
})(GateMeetingRoleTypeEnum || (GateMeetingRoleTypeEnum = {}));
export var ImportProjectFromNorrdByIdResultEnum;
(function (ImportProjectFromNorrdByIdResultEnum) {
    ImportProjectFromNorrdByIdResultEnum["NoChanges"] = "NoChanges";
    ImportProjectFromNorrdByIdResultEnum["Success"] = "Success";
    ImportProjectFromNorrdByIdResultEnum["Fail"] = "Fail";
})(ImportProjectFromNorrdByIdResultEnum || (ImportProjectFromNorrdByIdResultEnum = {}));
export var ApiKeyStatusEnum;
(function (ApiKeyStatusEnum) {
    ApiKeyStatusEnum["Enabled"] = "Enabled";
    ApiKeyStatusEnum["Disabled"] = "Disabled";
})(ApiKeyStatusEnum || (ApiKeyStatusEnum = {}));
export var FunctionCode = /** @class */ (function () {
    function FunctionCode() {
    }
    FunctionCode.ProjectManagement = 'PjM';
    return FunctionCode;
}());
export var Notifications = /** @class */ (function () {
    function Notifications() {
    }
    Notifications.ErrorForInvalidReadyForApprovalCheckListString = 'In the Function Checklist, all deliverables have to be uploaded, commented and rated (or marked NA and commented) and all questions have to be answered and rated';
    return Notifications;
}());
export var Warnings = /** @class */ (function () {
    function Warnings() {
    }
    Warnings.DeliverableAsLinkWarning = 'Warning: The deliverable linked is approved outside of TeamGate. When approved externally, the deliverable can be moved into approval and it will auto approve here.';
    return Warnings;
}());
export var AppData = /** @class */ (function () {
    function AppData() {
    }
    AppData.TaskUrlPart = 'tsk-';
    AppData.ProjectCodeNameQueryParameter = 'projectCodeName';
    AppData.AssigneeQueryParameter = 'assignee';
    AppData.ManualTaskDeadlineDiff = -21;
    return AppData;
}());
export var Sizes = /** @class */ (function () {
    function Sizes() {
    }
    Sizes.IconSize = 17;
    Sizes.IconSizeSmall = 16;
    return Sizes;
}());
export var AppColors = /** @class */ (function () {
    function AppColors() {
    }
    AppColors.Red = '#C60000';
    AppColors.Green = '#00B050';
    AppColors.LightGreen = '#00FF00';
    AppColors.Orange = 'orange';
    AppColors.Yellow = '#FFEF00';
    AppColors.Gray = '#999999';
    AppColors.LightGray = '#dddddd';
    AppColors.DarkGray = '#333333';
    AppColors.White = '#ffffff';
    AppColors.LightBlue = 'lightblue';
    AppColors.ButtonPrimary = '#0076B7';
    AppColors.ButtonPrimaryHover = '#5098F8';
    AppColors.ButtonSuccess = '#00B050';
    AppColors.ButtonSuccessHover = '#98DB38';
    AppColors.ButtonDanger = '#FF000F';
    AppColors.ButtonDangerHover = '#FF000F';
    AppColors.ButtonDefault = '#999999';
    AppColors.ButtonDefaultHover = '#ADADAD';
    return AppColors;
}());
export var ColorBlindModeDefaultColors = /** @class */ (function () {
    function ColorBlindModeDefaultColors() {
    }
    ColorBlindModeDefaultColors.Red = '#FF0000';
    ColorBlindModeDefaultColors.Green = '#00B050';
    ColorBlindModeDefaultColors.Orange = '#FF8C00';
    ColorBlindModeDefaultColors.Yellow = '#FFFF00';
    ColorBlindModeDefaultColors.Gray = '#999999';
    return ColorBlindModeDefaultColors;
}());
