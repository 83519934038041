var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { observer } from 'mobx-react';
import styles from './ApprovedChecklistItemTables.module.scss';
import { Column } from 'devextreme-react/data-grid';
import { FunctionList } from '@app/Components/Grid/FunctionList/FunctionList';
import { DocumentStatusIcon } from '@app/Components/DocumentStatusIcon/DocumentStatusIcon';
import HTMLReactParser from 'html-react-parser';
import columnSharedStyles from '@app/Components/Grid/SharedStyles/column-shared-styles.module.scss';
import { EditCell } from '@app/Components/Grid/EditCell/EditCell';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import { ApprovalApplicationEnum, CheckListItemRatingEnum, ChecklistSections, ObjectType } from '@app/AppConstants';
import { AssessorRatingCell } from '@app/Pages/ProjectView/ProjectViewTabs/GatePreparations/ApprovedGateView/ApprovedChecklistView/ApprovedChecklistDeliversTable/AssessorRaitingCell/AssessorRaitingCell';
import { DocumentButton } from '@app/Components/DocumentButton/DocumentButton';
import { action, computed, observable } from 'mobx';
import { Api } from '@app/Api';
import { Grid } from '@app/Components/Grid/Grid';
import { CodeCell } from '@app/Components/Grid/CodeCell/CodeCell';
import { ChangeLogDialog } from '@app/Components/Dialogs/ChangeLogDialog';
import { Route, Switch, withRouter } from 'react-router-dom';
import { DocumentView } from '@app/Widgets/DocumentView/DocumentView';
var ApprovedChecklistItemDeliverableTablePartC = /** @class */ (function (_super) {
    __extends(ApprovedChecklistItemDeliverableTablePartC, _super);
    function ApprovedChecklistItemDeliverableTablePartC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._loading = false;
        _this._isFunctionOkButtonVisible = false;
        _this._isLogVisible = false;
        _this._documentViewSection = ChecklistSections.Function;
        _this._delivers = [];
        _this._gridRef = React.createRef();
        return _this;
    }
    ApprovedChecklistItemDeliverableTablePartC.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadConfigs()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadDelivers()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistItemDeliverableTablePartC.prototype._loadConfigs = function () {
        return __awaiter(this, void 0, void 0, function () {
            var body;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.isFunctionOkButtonVisiable({
                            checklistId: this.props.checkListId
                        })];
                    case 1:
                        body = (_a.sent()).body;
                        this._isFunctionOkButtonVisible = body;
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistItemDeliverableTablePartC.prototype._loadDelivers = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        this._loading = true;
                        return [4 /*yield*/, Api.secured.getApprovedChecklistItem({
                                checkListItemId: this.props.checkListItemId
                            })];
                    case 1:
                        result = _a.sent();
                        this._delivers = [result];
                        return [3 /*break*/, 3];
                    case 2:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistItemDeliverableTablePartC.prototype._toggleLogModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._isLogVisible = !this._isLogVisible;
                return [2 /*return*/];
            });
        });
    };
    ApprovedChecklistItemDeliverableTablePartC.prototype._handleFunctionRatingCellChange = function (rowData, rating, rowIndex) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (rowData.rating !== rating) {
                    rowData.rating = rating;
                    if (this._gridRef.current) {
                        this._gridRef.current.repaintRows([rowIndex]);
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    ApprovedChecklistItemDeliverableTablePartC.prototype._handleDocumentClick = function (ciid, section) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._documentViewSection = section;
                this.props.history.push("".concat(this.props.match.url, "/upload/cl-").concat(ciid));
                return [2 /*return*/];
            });
        });
    };
    Object.defineProperty(ApprovedChecklistItemDeliverableTablePartC.prototype, "currentDeliverable", {
        get: function () {
            if (this._delivers && this._delivers.length === 1)
                return this._delivers[0];
            return null;
        },
        enumerable: false,
        configurable: true
    });
    ApprovedChecklistItemDeliverableTablePartC.prototype._saveChecklistItem = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.updateCLIRelatedManualTaskWorkerPart({
                            manualTaskId: this.props.manualTaskId,
                            body: rowData
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistItemDeliverableTablePartC.prototype._saveOkFunctionPart = function (checkListItemId) {
        return __awaiter(this, void 0, void 0, function () {
            var row, rowToUpdateId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.okCLIRelatedManualTask({
                            manualTaskId: this.props.manualTaskId,
                            checkListItemId: checkListItemId
                        })];
                    case 1:
                        row = _a.sent();
                        rowToUpdateId = this._delivers.findIndex(function (d) { return d.id === checkListItemId; });
                        if (rowToUpdateId !== -1) {
                            this._delivers.splice(rowToUpdateId, 1, row);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistItemDeliverableTablePartC.prototype.onFileChange = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadDelivers()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistItemDeliverableTablePartC.prototype.render = function () {
        var _this = this;
        var canPrincipalUpdateWorkerPart = this.props.canPrincipalUpdateWorkerPart;
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { ref: this._gridRef, dataSource: __spreadArray([], this._delivers, true), rowsCount: this._delivers.length, expandableRows: true, loading: this._loading },
                React.createElement(Column, { caption: "ID", dataField: "code", width: 150, allowEditing: false, cssClass: columnSharedStyles.colWithHistory, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(CodeCell, { code: data.code, onHistoryClick: function () { return (_this._isLogVisible = true); } }));
                    } }),
                React.createElement(Column, { caption: "Functions", dataField: "functionNames", width: 90, allowEditing: false, cellRender: function (_a) {
                        var data = _a.data;
                        if (!data.functionNames)
                            return null;
                        return React.createElement(FunctionList, { functions: data.functionNames, rowId: '' + data.id });
                    } }),
                React.createElement(Column, { caption: "Deliverable Text", dataField: "deliverableText", width: 300, allowEditing: false }),
                React.createElement(Column, { width: 40, alignment: "center", cellRender: function (_a) {
                        var _b, _c;
                        var data = _a.data;
                        return ((_b = data.deliverable) === null || _b === void 0 ? void 0 : _b.stateAtGateAssessment) &&
                            ((_c = data.deliverable) === null || _c === void 0 ? void 0 : _c.currentStatus) && (React.createElement(DocumentStatusIcon, { targetStatus: data.deliverable.stateAtGateAssessment, isFileUploaded: data.deliverable.hasFile, isCommented: data.deliverable.hasComment, isRated: data.deliverable.hasRating, currentStatus: data.deliverable.currentStatus, deliverableId: data.id, isExternalManagement: data.deliverable.approvalApplication !== ApprovalApplicationEnum.TeamGate, isNotApplicable: data.deliverable.isApprovalDisabled ||
                                data.deliverable.isNotApplicableAfterApproval, rating: data.deliverable.rating }));
                    } }),
                React.createElement(Column, { caption: "Guideline", dataField: "guideline", minWidth: 250, allowEditing: false, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", { style: { whiteSpace: 'pre-line' } }, HTMLReactParser(data.guideline || '')));
                    } }),
                React.createElement(Column, { cssClass: columnSharedStyles.commentColumn, caption: "Function Comment", width: 300, dataField: "comment", cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement(EditCell, { value: data.comment, onChange: function (value) {
                                data.comment = value;
                                if (_this._gridRef.current) {
                                    _this._gridRef.current.repaintRows([rowIndex]);
                                }
                            }, onSave: function () { return _this._saveChecklistItem(data); }, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), section: ChecklistSections.Function, rowIndex: rowIndex, columnIndex: columnIndex, disabled: !canPrincipalUpdateWorkerPart ||
                                data.assessorRating === CheckListItemRatingEnum.NotApplicable ||
                                (data.projectManagerRating === CheckListItemRatingEnum.NotApplicable &&
                                    data.assessorRating === CheckListItemRatingEnum.Default) }));
                    } }),
                React.createElement(Column, { headerCellRender: function () { return React.createElement("div", { title: "Function Rating" }, "Rating"); }, width: 100, dataField: "rating", alignment: "center", cssClass: styles.ratingColumn, cellRender: function (_a) {
                        var _b, _c;
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement("section", null,
                            React.createElement("div", { className: styles.ratingCellWrapper },
                                React.createElement("div", { className: styles.ratingWrapper },
                                    React.createElement(AssessorRatingCell, { onSave: function () { return _this._saveChecklistItem(data); }, onSaveOk: function () { return __awaiter(_this, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, this._saveOkFunctionPart(data.id)];
                                                    case 1:
                                                        _a.sent();
                                                        if (this._gridRef.current) {
                                                            this._gridRef.current.repaintRows([rowIndex]);
                                                        }
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); }, onChange: function (changedData) {
                                            return _this._handleFunctionRatingCellChange(data, changedData, rowIndex);
                                        }, rowIndex: rowIndex, columnIndex: columnIndex, rating: data.rating, showValidationErrors: false, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), section: ChecklistSections.Function, isOkButtonVisible: _this._isFunctionOkButtonVisible, disabled: !canPrincipalUpdateWorkerPart ||
                                            data.assessorRating === CheckListItemRatingEnum.NotApplicable ||
                                            (data.projectManagerRating === CheckListItemRatingEnum.NotApplicable &&
                                                data.assessorRating === CheckListItemRatingEnum.Default) }))),
                            React.createElement("div", { className: styles.commentActions },
                                React.createElement("div", { className: "".concat(styles.commentAction, " ").concat(styles.documentIconWrapper) },
                                    React.createElement(DocumentButton, { hasDocument: !!((_b = data === null || data === void 0 ? void 0 : data.deliverable) === null || _b === void 0 ? void 0 : _b.fileInformation), onDocumentIconClick: function () {
                                            return _this._handleDocumentClick(data.id, ChecklistSections.Function);
                                        }, className: styles.documentIconButton, hasRedDot: (_c = data.deliverable) === null || _c === void 0 ? void 0 : _c.hasRedDot })))));
                    } })),
            this._isLogVisible && this.currentDeliverable && (React.createElement(ChangeLogDialog, { onToggle: this._toggleLogModal, uniqueId: createUniqueId(ObjectType.ChecklistItem, this.currentDeliverable.id) })),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "".concat(this.props.match.url, "/upload/cl-:id") },
                    React.createElement(DocumentView, { backUrl: this.props.match.url, section: this._documentViewSection, onFileChange: this.onFileChange, isProjectTerminatedOrCompleted: this.props.isProjectTerminatedOrCompleted })))));
    };
    __decorate([
        observable
    ], ApprovedChecklistItemDeliverableTablePartC.prototype, "_loading", void 0);
    __decorate([
        observable
    ], ApprovedChecklistItemDeliverableTablePartC.prototype, "_isFunctionOkButtonVisible", void 0);
    __decorate([
        observable
    ], ApprovedChecklistItemDeliverableTablePartC.prototype, "_isLogVisible", void 0);
    __decorate([
        observable.shallow
    ], ApprovedChecklistItemDeliverableTablePartC.prototype, "_delivers", void 0);
    __decorate([
        observable.ref
    ], ApprovedChecklistItemDeliverableTablePartC.prototype, "_gridRef", void 0);
    __decorate([
        action.bound
    ], ApprovedChecklistItemDeliverableTablePartC.prototype, "_loadConfigs", null);
    __decorate([
        action.bound
    ], ApprovedChecklistItemDeliverableTablePartC.prototype, "_loadDelivers", null);
    __decorate([
        action.bound
    ], ApprovedChecklistItemDeliverableTablePartC.prototype, "_toggleLogModal", null);
    __decorate([
        action.bound
    ], ApprovedChecklistItemDeliverableTablePartC.prototype, "_handleFunctionRatingCellChange", null);
    __decorate([
        action.bound
    ], ApprovedChecklistItemDeliverableTablePartC.prototype, "_handleDocumentClick", null);
    __decorate([
        computed
    ], ApprovedChecklistItemDeliverableTablePartC.prototype, "currentDeliverable", null);
    __decorate([
        action.bound
    ], ApprovedChecklistItemDeliverableTablePartC.prototype, "_saveChecklistItem", null);
    __decorate([
        action.bound
    ], ApprovedChecklistItemDeliverableTablePartC.prototype, "_saveOkFunctionPart", null);
    __decorate([
        action.bound
    ], ApprovedChecklistItemDeliverableTablePartC.prototype, "onFileChange", null);
    ApprovedChecklistItemDeliverableTablePartC = __decorate([
        observer
    ], ApprovedChecklistItemDeliverableTablePartC);
    return ApprovedChecklistItemDeliverableTablePartC;
}(React.Component));
export var ApprovedChecklistItemDeliverableTablePart = withRouter(ApprovedChecklistItemDeliverableTablePartC);
