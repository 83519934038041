var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import styles from './ProjectsOverview.module.scss';
import { observer } from 'mobx-react';
import { observable, action, computed } from 'mobx';
import approvedImg from 'assets/images/status.app.sm.png';
import completedImg from 'assets/images/status.com.sm.png';
import terminatedImg from 'assets/images/status.term.sm.png';
import { Api } from '@app/Api';
import { ProjectsOverviewInfoline } from './ProjectsOverviewInfoline/ProjectsOverviewInfoline';
import { ProjectsOverviewGates } from './ProjectsOverviewGates/ProjectsOverviewGates';
import { Grid } from '../Grid/Grid';
import { Column, Scrolling, FilterRow, Sorting } from 'devextreme-react/data-grid';
import { Link } from '@app/Components/Link/Link';
import { FavouriteToggler } from '@app/Components/FavoriteToggler/FavouriteToggler';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
export var ProjectStatuses;
(function (ProjectStatuses) {
    ProjectStatuses["APPROVED"] = "Approved";
    ProjectStatuses["COMPLETED"] = "Completed";
    ProjectStatuses["TERMINATED"] = "Terminated";
})(ProjectStatuses || (ProjectStatuses = {}));
export var ProjectsOverview = /** @class */ (function (_super) {
    __extends(ProjectsOverview, _super);
    function ProjectsOverview() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._projects = [];
        _this._canCreateProject = false;
        _this._openCreateProjectModal = false;
        _this._lastFilterOptionsRequest = '';
        _this.isDataLoading = false;
        return _this;
    }
    Object.defineProperty(ProjectsOverview.prototype, "projectsLength", {
        get: function () {
            return this._projects.length;
        },
        enumerable: false,
        configurable: true
    });
    ProjectsOverview.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var body;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.canCreateProject()];
                    case 1:
                        body = (_a.sent()).body;
                        this._canCreateProject = body;
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectsOverview.prototype._loadProjects = function (filter) {
        return __awaiter(this, void 0, void 0, function () {
            var newFilterOptionsRequest, projs, top_1, bot;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        this.isDataLoading = true;
                        newFilterOptionsRequest = JSON.stringify(filter);
                        this._lastFilterOptionsRequest = newFilterOptionsRequest;
                        return [4 /*yield*/, Api.secured.getProjects(__assign({ size: 1000 }, filter))];
                    case 1:
                        projs = _a.sent();
                        if (this._lastFilterOptionsRequest === newFilterOptionsRequest) {
                            top_1 = projs.filter(function (p) { return p.isProjectFavorite; }).sort(function (a, b) { return a.id - b.id; });
                            bot = projs.filter(function (p) { return !p.isProjectFavorite; }).sort(function (a, b) { return a.id - b.id; });
                            this._projects = __spreadArray(__spreadArray([], top_1, true), bot, true);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        this.isDataLoading = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProjectsOverview.prototype._renderGates = function (data) {
        if (!data.gateActionItemLinks || !data.gateActionItemLinks.length)
            return '';
        var gates = [];
        for (var i = 0; i < data.gateActionItemLinks.length; i++) {
            var gate = data.gateActionItemLinks[i];
            gates.push(React.createElement(Link, { key: "gate-link-".concat(gate.id), to: "/projects/".concat(data.codeName, "/deliverables/g-").concat(gate.id) }, "".concat(gate.displayName)));
            if (i < data.gateActionItemLinks.length - 1) {
                gates.push(', ');
            }
        }
        return gates;
    };
    ProjectsOverview.prototype.reloadProjects = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadProjects(params)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectsOverview.prototype.render = function () {
        var _this = this;
        return (React.createElement(ErrorBoundary, null,
            React.createElement(Grid, { dataSource: this._projects, style: { maxHeight: '80vh' }, loading: this.isDataLoading },
                React.createElement(Scrolling, { mode: "infinite" }),
                React.createElement(Sorting, { mode: "multiple" }),
                React.createElement(FilterRow, { visible: false }),
                React.createElement(Column, { caption: "Status", dataField: "status", width: 50, alignment: "center", cellRender: function (_a) {
                        var data = _a.data;
                        if (!(data === null || data === void 0 ? void 0 : data.status))
                            return null;
                        var projectStatusImg;
                        switch (data.status) {
                            case ProjectStatuses.APPROVED:
                                projectStatusImg = approvedImg;
                                break;
                            case ProjectStatuses.COMPLETED:
                                projectStatusImg = completedImg;
                                break;
                            case ProjectStatuses.TERMINATED:
                                projectStatusImg = terminatedImg;
                                break;
                            default:
                                projectStatusImg = '';
                        }
                        return projectStatusImg ? (React.createElement("img", { className: styles.image, src: projectStatusImg, alt: data.status, title: data.status })) : null;
                    } }),
                React.createElement(Column, { caption: "Project", width: 100, dataField: "codeName", alignment: "center", cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(Link, { to: "projects/".concat(data.codeName) }, data.codeName));
                    } }),
                React.createElement(Column, { caption: "Name", dataField: "displayName", minWidth: 200 }),
                React.createElement(Column, { caption: "Favorites", dataField: "isProjectFavorite", width: 80, allowSorting: false, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(FavouriteToggler, { checked: data.isProjectFavorite, onToggle: function (checked) { return _this._toggleProjectFavorite(checked, data.id); } }));
                    } }),
                React.createElement(Column, { caption: "Lead Org Unit", dataField: "leadResponsibleOrgUnitDisplayText", width: 200, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", null, data.leadResponsibleOrgUnitDisplayText));
                    } }),
                React.createElement(Column, { caption: "Leader", dataField: "responsibleProjectLeader", width: 200, alignment: "center" }),
                React.createElement(Column, { headerCellRender: function () { return React.createElement(ProjectsOverviewInfoline, null); }, minWidth: 400, cellRender: function (_a) {
                        var data = _a.data;
                        return React.createElement(ProjectsOverviewGates, { prj: data });
                    } }),
                React.createElement(Column, { headerCellRender: function () { return React.createElement("div", { title: "Action Items for closed Gates" }, "AIs"); }, dataField: "gateActionItemLinks", width: 55, cellRender: function (_a) {
                        var data = _a.data;
                        return _this._renderGates(data);
                    } }))));
    };
    ProjectsOverview.prototype._toggleProjectFavorite = function (checked, projectId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!checked) return [3 /*break*/, 2];
                        return [4 /*yield*/, Api.secured.addFavoriteProjectForCurrentPrincipal({ projectId: projectId })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, Api.secured.deleteFavoriteProjectForCurrentPrincipal({ projectId: projectId })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [4 /*yield*/, this._loadProjects()];
                    case 5:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        observable
    ], ProjectsOverview.prototype, "_projects", void 0);
    __decorate([
        observable
    ], ProjectsOverview.prototype, "_canCreateProject", void 0);
    __decorate([
        observable
    ], ProjectsOverview.prototype, "_openCreateProjectModal", void 0);
    __decorate([
        observable
    ], ProjectsOverview.prototype, "isDataLoading", void 0);
    __decorate([
        computed
    ], ProjectsOverview.prototype, "projectsLength", null);
    __decorate([
        action
    ], ProjectsOverview.prototype, "_loadProjects", null);
    __decorate([
        action
    ], ProjectsOverview.prototype, "reloadProjects", null);
    __decorate([
        action.bound
    ], ProjectsOverview.prototype, "_toggleProjectFavorite", null);
    ProjectsOverview = __decorate([
        observer
    ], ProjectsOverview);
    return ProjectsOverview;
}(React.PureComponent));
