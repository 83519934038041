var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import styles from './ProjectViewDescription.module.scss';
import { Api } from '@app/Api';
import { DateBox } from '@app/Widgets/DateBox/DateBox';
import { Button } from '@app/Components/Button/Button';
import { UpdateProjectGeneralInfoModal } from '@app/Widgets/UpdateProjectGeneralInfoModal/UpdateProjectGeneralInfoModal';
import { SeparatedStrings, ProjectSecurityLevelEnum, ProjectCategoryEnum } from '@app/AppConstants';
export var ProjectViewDescription = /** @class */ (function (_super) {
    __extends(ProjectViewDescription, _super);
    function ProjectViewDescription(props) {
        var _this = _super.call(this, props) || this;
        _this._openUpdateProjectModal = false;
        _this._initProjectUpdateModel();
        return _this;
    }
    ProjectViewDescription.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var securityLevels, categories;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.getProjectSecurityLevelEnum()];
                    case 1:
                        securityLevels = _a.sent();
                        return [4 /*yield*/, Api.secured.getProjectCategoryEnum()];
                    case 2:
                        categories = _a.sent();
                        this._securityLevels = securityLevels.map(function (x) { return ({ value: '' + x.value, label: '' + x.label }); });
                        this._categories = categories.map(function (x) { return ({ value: '' + x.value, label: '' + x.label }); });
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectViewDescription.prototype._initProjectUpdateModel = function () {
        var project = this.props.project;
        this._projectUpdateModel = {
            projectId: project.id,
            displayName: project.displayName,
            responsibleOrgUnitId: project.responsibleOrgUnitId,
            leaderPrincipalId: project.leaderPrincipalModel.id,
            plannedStartDate: project.plannedStartDate,
            plannedEndDate: project.plannedEndDate,
            securityLevel: project.securityLevel,
            category: project.category,
            totalBudget: project.totalBudget / 1000,
            intellectualPropertyKeyProject: !!project.intellectualPropertyKeyProject,
            isDigital: !!project.isDigital
        };
    };
    ProjectViewDescription.prototype._toggleUpdateProjectGeneralInfoModal = function () {
        this._openUpdateProjectModal = !this._openUpdateProjectModal;
    };
    ProjectViewDescription.prototype._toggleSaveProjectModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.props.onChange) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.props.onChange()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ProjectViewDescription.prototype.renderProjectSecurityLevel = function (securityLevel) {
        if (securityLevel === ProjectSecurityLevelEnum.StrictlyConfidential) {
            return SeparatedStrings.ProjectSecurityLevelStrictlyConfidential;
        }
        return securityLevel;
    };
    ProjectViewDescription.prototype.renderProjectCategory = function (category) {
        switch (category) {
            case ProjectCategoryEnum.NewGenerationProduct:
                return SeparatedStrings.ProjectCategoryNewGenerationProduct;
            case ProjectCategoryEnum.NextGenerationProduct:
                return SeparatedStrings.ProjectCategoryNextGenerationProduct;
            case ProjectCategoryEnum.ProductEnhancement:
                return SeparatedStrings.ProjectCategoryProductEnhancement;
            case ProjectCategoryEnum.ProductMaintenance:
                return SeparatedStrings.ProjectCategoryProductMaintenance;
            case ProjectCategoryEnum.TechnologyDevelopment:
                return SeparatedStrings.ProjectCategoryTechnologyDevelopment;
            case ProjectCategoryEnum.PreStudiesAndScouting:
                return SeparatedStrings.ProjectCategoryPreStudiesAndScouting;
            case ProjectCategoryEnum.RAndDInfrastructure:
                return SeparatedStrings.ProjectCategoryRAndDInfrastructure;
        }
    };
    ProjectViewDescription.prototype.renderBoolean = function (value) {
        return !!value ? 'Yes' : 'No';
    };
    ProjectViewDescription.prototype.render = function () {
        var project = this.props.project;
        var canUpdate = project.canPrincipalUpdateGeneralInfo;
        var isNorrdRelatedProject = !!project.norrdProjectLink;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.line },
                React.createElement("div", { className: styles.item },
                    React.createElement("div", { className: styles.title },
                        "Project Start Date",
                        isNorrdRelatedProject && React.createElement("span", { className: styles.star }, "*")),
                    React.createElement("div", { className: styles.data },
                        React.createElement(DateBox, { date: project.plannedStartDate }))),
                React.createElement("div", { className: styles.item },
                    React.createElement("div", { className: styles.title },
                        "Project End Date",
                        isNorrdRelatedProject && React.createElement("span", { className: styles.star }, "*")),
                    React.createElement("div", { className: styles.data },
                        React.createElement(DateBox, { date: project.plannedEndDate }))),
                React.createElement("div", { className: styles.item },
                    React.createElement("div", { className: styles.title },
                        "Total Budget",
                        isNorrdRelatedProject && React.createElement("span", { className: styles.star }, "*")),
                    React.createElement("div", { className: styles.currencyBlock },
                        React.createElement("span", { className: styles.currencyValue }, this.roundBudget(project.totalBudget)),
                        React.createElement("span", { className: styles.currencyPostfix }, "KUSD"))),
                React.createElement("div", { className: styles.item },
                    React.createElement("div", { className: styles.title },
                        "Lead Responsible OrgUnit",
                        isNorrdRelatedProject && React.createElement("span", { className: styles.star }, "*")),
                    React.createElement("div", { className: styles.data }, project.leadResponsibleOrgUnitDisplayText)),
                React.createElement("div", { className: styles.item },
                    React.createElement("div", { className: styles.title },
                        "Security Level",
                        isNorrdRelatedProject && React.createElement("span", { className: styles.star }, "*")),
                    React.createElement("div", { className: styles.data }, this.renderProjectSecurityLevel(project.securityLevel))),
                React.createElement("div", { className: styles.item },
                    React.createElement("div", { className: styles.title },
                        "Total Budget Forecast",
                        isNorrdRelatedProject && React.createElement("span", { className: styles.star }, "*")),
                    React.createElement("div", { className: styles.currencyBlock },
                        React.createElement("span", { className: styles.currencyValue }, project.totalBudgetForecast && this.roundBudget(project.totalBudgetForecast)),
                        React.createElement("span", { className: styles.currencyPostfix }, "KUSD"))),
                React.createElement("div", { className: styles.item },
                    React.createElement("div", { className: styles.title },
                        "Project Category",
                        isNorrdRelatedProject && React.createElement("span", { className: styles.star }, "*")),
                    React.createElement("div", { className: styles.data }, this.renderProjectCategory(project.category))),
                React.createElement("div", { className: styles.item },
                    React.createElement("div", { className: styles.title },
                        "Project Manager (Project Leader)",
                        isNorrdRelatedProject && React.createElement("span", { className: styles.star }, "*")),
                    React.createElement("div", { className: styles.data }, project.responsibleProjectLeader)),
                React.createElement("div", { className: styles.item },
                    React.createElement("div", { className: styles.title },
                        "IP Key",
                        isNorrdRelatedProject && React.createElement("span", { className: styles.star }, "*")),
                    React.createElement("div", { className: styles.data }, this.renderBoolean(project.intellectualPropertyKeyProject))),
                React.createElement("div", { className: styles.item },
                    React.createElement("div", { className: styles.title },
                        "Digital",
                        isNorrdRelatedProject && React.createElement("span", { className: styles.star }, "*")),
                    React.createElement("div", { className: styles.data }, this.renderBoolean(project.isDigital))),
                isNorrdRelatedProject && (React.createElement("div", { className: styles.item },
                    React.createElement("div", { className: styles.title }, "NORRD Project Link"),
                    React.createElement("div", { className: styles.data },
                        React.createElement("a", { className: styles.link, href: project.norrdProjectLink, rel: "noopener noreferrer", target: "_blank" }, project.displayName))))),
            canUpdate && (React.createElement("div", null,
                React.createElement(Button, { onClick: this._toggleUpdateProjectGeneralInfoModal }, "Edit"))),
            React.createElement("div", { className: styles.fieldDescription },
                "FIELDS DESCRIPTION: ",
                React.createElement("span", { className: styles.star }, "*"),
                " Editable in NORRD"),
            React.createElement(UpdateProjectGeneralInfoModal, { projectUpdateModel: this._projectUpdateModel, leaderPrincipalModel: project.leaderPrincipalModel, isOpen: this._openUpdateProjectModal, onToggle: this._toggleUpdateProjectGeneralInfoModal, onSave: this._toggleSaveProjectModal, securityLevels: this._securityLevels, categories: this._categories })));
    };
    ProjectViewDescription.prototype.roundBudget = function (value) {
        return (value / 1000).toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };
    __decorate([
        observable
    ], ProjectViewDescription.prototype, "_projectUpdateModel", void 0);
    __decorate([
        observable
    ], ProjectViewDescription.prototype, "_securityLevels", void 0);
    __decorate([
        observable
    ], ProjectViewDescription.prototype, "_categories", void 0);
    __decorate([
        observable
    ], ProjectViewDescription.prototype, "_openUpdateProjectModal", void 0);
    __decorate([
        action.bound
    ], ProjectViewDescription.prototype, "_initProjectUpdateModel", null);
    __decorate([
        action.bound
    ], ProjectViewDescription.prototype, "_toggleUpdateProjectGeneralInfoModal", null);
    __decorate([
        action.bound
    ], ProjectViewDescription.prototype, "_toggleSaveProjectModal", null);
    ProjectViewDescription = __decorate([
        observer
    ], ProjectViewDescription);
    return ProjectViewDescription;
}(React.PureComponent));
