var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import styles from './ApprovedChecklistItemTables.module.scss';
import { observer } from 'mobx-react';
import { Column } from 'devextreme-react/data-grid';
import HTMLReactParser from 'html-react-parser';
import columnSharedStyles from '@app/Components/Grid/SharedStyles/column-shared-styles.module.scss';
import { EditCell } from '@app/Components/Grid/EditCell/EditCell';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import { ChecklistSections, ObjectType } from '@app/AppConstants';
import { action, computed, observable } from 'mobx';
import { Api } from '@app/Api';
import { Grid } from '@app/Components/Grid/Grid';
import { CodeCell } from '@app/Components/Grid/CodeCell/CodeCell';
import { ChangeLogDialog } from '@app/Components/Dialogs/ChangeLogDialog';
import { withRouter } from 'react-router-dom';
import { RatingCell } from '@app/Components/Grid/RatingCell/RatingCell';
var ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC = /** @class */ (function (_super) {
    __extends(ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC, _super);
    function ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._loadingQuestions = false;
        _this._isLogVisible = false;
        _this._questions = [];
        return _this;
    }
    ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadQuestions()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC.prototype._loadQuestions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        this._loadingQuestions = true;
                        return [4 /*yield*/, Api.secured.getMilestoneQuestion({
                                checklistItemId: this.props.checkListItemId
                            })];
                    case 1:
                        result = _a.sent();
                        this._questions = [result];
                        return [3 /*break*/, 3];
                    case 2:
                        this._loadingQuestions = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC.prototype._toggleLogModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._isLogVisible = !this._isLogVisible;
                return [2 /*return*/];
            });
        });
    };
    Object.defineProperty(ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC.prototype, "currentQuestion", {
        get: function () {
            if (this._questions && this._questions.length === 1)
                return this._questions[0];
            return null;
        },
        enumerable: false,
        configurable: true
    });
    ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC.prototype._saveChecklistItem = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.updateMilestoneExecutiveQuestionRelatedManualTaskWorkerPart({
                            manualTaskId: this.props.manualTaskId,
                            body: rowData
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC.prototype.render = function () {
        var _this = this;
        var canPrincipalUpdateWorkerPart = this.props.canPrincipalUpdateWorkerPart;
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { expandableRows: true, dataSource: __spreadArray([], this._questions, true), rowsCount: this._questions.length, loading: this._loadingQuestions },
                React.createElement(Column, { caption: "ID", dataField: "code", width: 100, allowEditing: false, cssClass: columnSharedStyles.colWithHistory, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(CodeCell, { code: data.code, onHistoryClick: function () { return (_this._isLogVisible = true); } }));
                    } }),
                React.createElement(Column, { caption: "Question", dataField: "question", allowEditing: false, width: 350, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", null,
                            data.questionTitle && (React.createElement("div", { className: styles.questionTitle }, data.questionTitle)),
                            React.createElement("div", null, HTMLReactParser(data.question || ''))));
                    } }),
                React.createElement(Column, { caption: "Guideline", dataField: "guideline", allowEditing: false, minWidth: 300, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", null,
                            data.questionTitle && (React.createElement("div", { className: styles.questionTitle }, data.questionTitle)),
                            React.createElement("div", { style: { whiteSpace: 'pre-line' } }, HTMLReactParser(data.guideline || ''))));
                    } }),
                React.createElement(Column, { caption: "Project Manager Comments", dataField: "projectManagerOriginalComment", width: 250, cssClass: columnSharedStyles.commentColumn, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(EditCell, { value: data.projectManagerOriginalComment, onChange: function (value) { return (data.projectManagerOriginalComment = value); }, onSave: function () { return _this._saveChecklistItem(data); }, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), withCopy: true, disabled: !canPrincipalUpdateWorkerPart || !data.isGateEditable, section: ChecklistSections.ProjectManager }));
                    } }),
                React.createElement(Column, { headerCellRender: function () { return React.createElement("div", { title: "Project Manager Rating" }, "Rating"); }, width: 100, cssClass: styles.ratingColumn, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("section", null,
                            React.createElement("div", { className: styles.ratingCellWrapper },
                                React.createElement("div", { className: styles.ratingWrapper },
                                    React.createElement(RatingCell, { onChange: function (value) {
                                            data.projectManagerRating = value;
                                        }, onSave: function () { return _this._saveChecklistItem(data); }, rating: data.projectManagerRating, showValidationError: false, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), disabled: !canPrincipalUpdateWorkerPart || !data.isGateEditable, section: ChecklistSections.ProjectManager, controlId: "pmRating".concat(data.id) })))));
                    } })),
            this._isLogVisible && this.currentQuestion && (React.createElement(ChangeLogDialog, { onToggle: this._toggleLogModal, uniqueId: createUniqueId(ObjectType.ChecklistItem, this.currentQuestion.id) }))));
    };
    __decorate([
        observable
    ], ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC.prototype, "_loadingQuestions", void 0);
    __decorate([
        observable
    ], ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC.prototype, "_isLogVisible", void 0);
    __decorate([
        observable.shallow
    ], ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC.prototype, "_questions", void 0);
    __decorate([
        action.bound
    ], ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC.prototype, "_loadQuestions", null);
    __decorate([
        action.bound
    ], ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC.prototype, "_toggleLogModal", null);
    __decorate([
        computed
    ], ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC.prototype, "currentQuestion", null);
    __decorate([
        action.bound
    ], ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC.prototype, "_saveChecklistItem", null);
    ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC = __decorate([
        observer
    ], ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC);
    return ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC;
}(React.Component));
export var ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePart = withRouter(ApprovedMilestoneChecklistItemMasterExecutiveQuestionTablePartC);
