var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import styles from './MeetingGateView.module.scss';
import { withRouter, Switch, Route } from 'react-router-dom';
import { Button } from '@app/Components/Button/Button';
import { Api } from '@app/Api';
import { DecisionTable } from './DecisionTable/DecisionTable';
import { FunctionFilter } from '@app/Components/FunctonFilter/FunctionFilter';
import { FunctionFilterContext } from '@app/Components/FunctonFilter/FunctionFilterContext';
import { RatingFilterMeeting } from '@app/Components/RatingFilterMeeting/RatingFilterMeeting';
import { RatingFilterMeetingContext } from '@app/Components/RatingFilterMeeting/RatingFilterMeetingContext';
import ActionItemsTable from '../Components/ActionItemsTable/ActionItemsTable';
import { ChecklistSections, Events, GateProcessTypeEnum, ProjectState } from '@app/AppConstants';
import { FunctionSummariesTable } from './FunctionSummariesTable/FunctionSummariesTable';
import { ExecutiveQuestionsTable } from './ExecutiveQuestionsTable/ExecutiveQuestionsTable';
import { DeliversTable } from './DeliversTable/DeliversTable';
import { Meeting } from '@app/Widgets/Meeting/Meeting';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { ProjectStateContext } from '../../Components/ProjectStateContext';
import { DocumentView } from '@app/Widgets/DocumentView/DocumentView';
import { ConfirmButton } from '@app/Components/ConfirmButton/ConfirmButton';
import { ConfirmationDialog } from '@app/Widgets/ConfirmationDialog/ConfirmationDialog';
import { LoadingContext } from '@app/Context/LoadingContext';
var MeetingGateViewC = /** @class */ (function (_super) {
    __extends(MeetingGateViewC, _super);
    function MeetingGateViewC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._isFilesChanged = false;
        _this._isConfirmationModalOpen = false;
        _this._documentViewSection = ChecklistSections.Assessor;
        _this.refreshActionItems = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                document.dispatchEvent(new Event('refreshActionItems'));
                return [2 /*return*/];
            });
        }); };
        return _this;
    }
    MeetingGateViewC.prototype._toggleConfirmationModalOpen = function () {
        this._isConfirmationModalOpen = !this._isConfirmationModalOpen;
    };
    MeetingGateViewC.prototype._handleSaveOkClick = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._toggleConfirmationModalOpen();
                return [2 /*return*/];
            });
        });
    };
    MeetingGateViewC.prototype._saveOkAssessorPart = function () {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        _g.trys.push([0, , 3, 4]);
                        (_b = (_a = this.context).setLoading) === null || _b === void 0 ? void 0 : _b.call(_a, true);
                        return [4 /*yield*/, Api.secured.okMeetingMasterCheckListItemAssessorParts({ gateId: this.props.gate.id })];
                    case 1:
                        _g.sent();
                        return [4 /*yield*/, ((_d = (_c = this.props).onRefreshChecklistStatusOverview) === null || _d === void 0 ? void 0 : _d.call(_c))];
                    case 2:
                        _g.sent();
                        document.dispatchEvent(new Event(Events.meetingGateDeliversConfirm));
                        document.dispatchEvent(new Event(Events.meetingGateExecutiveQuestionsConfirm));
                        document.dispatchEvent(new Event(Events.meetingGateFunctionSummariesConfirm));
                        this._toggleConfirmationModalOpen();
                        return [3 /*break*/, 4];
                    case 3:
                        (_f = (_e = this.context).setLoading) === null || _f === void 0 ? void 0 : _f.call(_e, false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MeetingGateViewC.prototype._closeMeetingModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, history, match;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, history = _a.history, match = _a.match;
                        return [4 /*yield*/, this.props.onRefresh()];
                    case 1:
                        _b.sent();
                        history.push("".concat(match.url));
                        return [2 /*return*/];
                }
            });
        });
    };
    MeetingGateViewC.prototype._handleDocumentClick = function (gateId, cliId, section) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._documentViewSection = section;
                this.props.history.push("".concat(this.props.match.url, "/g-").concat(gateId, "/upload/cl-").concat(cliId));
                return [2 /*return*/];
            });
        });
    };
    MeetingGateViewC.prototype.render = function () {
        var _this = this;
        var _a = this.props, gate = _a.gate, projectId = _a.projectId, match = _a.match, projectState = _a.projectState, onRefreshChecklistStatusOverview = _a.onRefreshChecklistStatusOverview, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.wrapper },
                React.createElement("div", { className: styles.table },
                    React.createElement("div", { className: styles.tableContainer },
                        gate.canMakeDecision && (React.createElement("div", { className: styles.decisionWrapper },
                            React.createElement("div", { className: styles.tableTitle }, "Decision"),
                            !gate.hasMeeting && (React.createElement(Button, { size: "sm", className: styles.decisionButtn, onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                    var res;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, Api.secured.createGateMeeting(gate.id)];
                                            case 1:
                                                res = _a.sent();
                                                if (!res.error) {
                                                    gate.hasMeeting = true;
                                                }
                                                return [2 /*return*/];
                                        }
                                    });
                                }); }, disabled: isProjectTerminatedOrCompleted }, "Add Decision")))),
                        gate.canPrincipalUpdateGateMeetingAssessorPart && (React.createElement("div", { className: styles.confirmButtonWrapper },
                            React.createElement("div", { className: styles.confirmButton },
                                React.createElement(ConfirmButton, { id: 'tooltipAssessmentsGa' + gate.id, tooltipText: "Rate ALL Deliverables, Confirmation of Functional Readiness and Questions green and set comments to 'OK' as GA (missing data only)", onClick: this._handleSaveOkClick, disabled: isProjectTerminatedOrCompleted }))))),
                    gate.hasMeeting && (React.createElement(DecisionTable, { gateId: gate.id, isMilestoneProject: projectState === ProjectState.Milestone, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted }))),
                React.createElement("div", { className: styles.functionFilterWrapper },
                    React.createElement(FunctionFilter, { actionForLoading: function () {
                            return Api.secured.getMasterCheckListItemFunctions({
                                projectId: projectId,
                                gateId: gate.id
                            });
                        } }),
                    React.createElement(RatingFilterMeeting, null)),
                React.createElement(RatingFilterMeetingContext.Provider, { value: RatingFilterMeeting.filterValue },
                    React.createElement(FunctionFilterContext.Provider, { value: FunctionFilter.filterValue },
                        React.createElement(ProjectStateContext.Consumer, null, function (projectStateContext) { return (React.createElement(FunctionFilterContext.Consumer, null, function (functionFilterValue) { return (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: styles.table },
                                React.createElement(ActionItemsTable, { projectId: projectId, gateId: gate.id, gateProcessType: GateProcessTypeEnum.GateMeeting, gateProcessTypeForCreation: GateProcessTypeEnum.GateMeeting, canAdd: gate.canCreateGateMeetingActionItems, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })),
                            React.createElement("div", { className: styles.table },
                                React.createElement("div", { className: styles.tableTitle }, "Deliverables"),
                                React.createElement(DeliversTable, { projectId: projectId, gateId: gate.id, isFilesChanged: _this._isFilesChanged, onDocumentIconClick: _this._handleDocumentClick, projectState: projectState, hideNAItems: projectStateContext.hideNAItems, showRedDotDeliverable: projectStateContext.showRedDotDeliverable, showDocuments: gate.hasApprovedRecommendation, onRefreshActionItems: _this.refreshActionItems, onRefreshChecklistStatusOverview: onRefreshChecklistStatusOverview, functionFilterValue: functionFilterValue, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })),
                            React.createElement("div", { className: styles.table },
                                React.createElement("div", { className: styles.tableTitle }, "Confirmation of Functional Readiness"),
                                React.createElement(FunctionSummariesTable, { projectId: projectId, gateId: gate.id, projectState: projectState, hideNAItems: projectStateContext.hideNAItems, onRefreshActionItems: _this.refreshActionItems, onRefreshChecklistStatusOverview: onRefreshChecklistStatusOverview, functionFilterValue: functionFilterValue, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })),
                            React.createElement("div", { className: styles.table },
                                React.createElement("div", { className: styles.tableTitle }, "Questions / Expectations"),
                                React.createElement(ExecutiveQuestionsTable, { projectId: projectId, projectState: projectState, gateId: gate.id, hideNAItems: projectStateContext.hideNAItems, onRefreshActionItems: _this.refreshActionItems, onRefreshChecklistStatusOverview: onRefreshChecklistStatusOverview, functionFilterValue: functionFilterValue, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })))); })); })))),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "".concat(match.url, "/meeting") },
                    React.createElement(Meeting, { onToggle: this._closeMeetingModal, projectId: projectId, gateId: gate.id, gateForecastOrActualDate: gate.forecastOrActualDate, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted })),
                React.createElement(Route, { path: "".concat(this.props.match.url, "/g-:gid/upload/cl-:id") },
                    React.createElement(DocumentView, { backUrl: "".concat(match.url, "/g-").concat(gate.id), section: this._documentViewSection, onFileChange: function () { return (_this._isFilesChanged = !_this._isFilesChanged); }, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted }))),
            React.createElement(ConfirmationDialog, { isOpen: this._isConfirmationModalOpen, onToggle: this._toggleConfirmationModalOpen, headerTitle: "Rate ALL Deliverables, Confirmation of Functional Readiness and Questions green and set comments to 'OK'", onSave: this._saveOkAssessorPart, body: "Are you sure you want to provide Gate Assessor input as \u2018OK' comment and green Rating for all relevant items, where input is missing ('OK' comment will be set only for the cases of green or missing Rating)?" })));
    };
    MeetingGateViewC.contextType = LoadingContext;
    __decorate([
        observable
    ], MeetingGateViewC.prototype, "_isFilesChanged", void 0);
    __decorate([
        observable
    ], MeetingGateViewC.prototype, "_isConfirmationModalOpen", void 0);
    __decorate([
        action.bound
    ], MeetingGateViewC.prototype, "_toggleConfirmationModalOpen", null);
    __decorate([
        action.bound
    ], MeetingGateViewC.prototype, "_handleSaveOkClick", null);
    __decorate([
        action.bound
    ], MeetingGateViewC.prototype, "_saveOkAssessorPart", null);
    __decorate([
        action.bound
    ], MeetingGateViewC.prototype, "_closeMeetingModal", null);
    __decorate([
        action.bound
    ], MeetingGateViewC.prototype, "_handleDocumentClick", null);
    MeetingGateViewC = __decorate([
        observer
    ], MeetingGateViewC);
    return MeetingGateViewC;
}(React.Component));
export var MeetingGateView = withRouter(MeetingGateViewC);
