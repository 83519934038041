var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Grid } from '@app/Components/Grid/Grid';
import { Column } from 'devextreme-react/data-grid';
import styles from './ChooseTemplateModal.module.scss';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Api } from '@app/Api';
import { Modal } from '@app/Components/Modal/Modal';
export var ChooseTemplateModal = /** @class */ (function (_super) {
    __extends(ChooseTemplateModal, _super);
    function ChooseTemplateModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._templates = [];
        _this._loading = false;
        return _this;
    }
    ChooseTemplateModal.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadTemplates()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChooseTemplateModal.prototype._loadTemplates = function () {
        return __awaiter(this, void 0, void 0, function () {
            var projectId, isNorrdProject, _a, defaultTemplate;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        projectId = (_b = this.props, _b.projectId), isNorrdProject = _b.isNorrdProject;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, , 3, 4]);
                        this._loading = true;
                        _a = this;
                        return [4 /*yield*/, Api.secured.getEnabledTemplates({ projectId: projectId, isNorrdProject: isNorrdProject })];
                    case 2:
                        _a._templates = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 4:
                        defaultTemplate = this._templates.find(function (x) { return x.isDefault; });
                        if (defaultTemplate) {
                            this._onRadioChange(defaultTemplate);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ChooseTemplateModal.prototype._save = function () {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!this._chosenTemplate)
                            return [2 /*return*/];
                        return [4 /*yield*/, ((_b = (_a = this.props).onChooseTemplate) === null || _b === void 0 ? void 0 : _b.call(_a, this._chosenTemplate))];
                    case 1:
                        _e.sent();
                        (_d = (_c = this.props).onToggle) === null || _d === void 0 ? void 0 : _d.call(_c);
                        return [2 /*return*/];
                }
            });
        });
    };
    ChooseTemplateModal.prototype._onRadioChange = function (template) {
        this._chosenTemplate = template;
    };
    ChooseTemplateModal.prototype.render = function () {
        var _this = this;
        var onToggle = this.props.onToggle;
        return (React.createElement(Modal, { isOpen: true, toggle: onToggle, headerTitle: "Choose Template", footerbuttons: this._getFooterButtons(), maxWidth: '95%' },
            React.createElement(Grid, { dataSource: this._templates, loading: this._loading },
                React.createElement(Column, { width: 70, cellRender: function (_a) {
                        var _b;
                        var data = _a.data;
                        return (React.createElement("label", { className: styles.cursor },
                            React.createElement("input", { type: "radio", onChange: function () { return _this._onRadioChange(data); }, checked: data.id === ((_b = _this._chosenTemplate) === null || _b === void 0 ? void 0 : _b.id), disabled: !data.isValid })));
                    } }),
                React.createElement(Column, { caption: "Template Name", dataField: "displayName", width: 350 }),
                React.createElement(Column, { caption: "Is Valid", dataField: "isValid", width: 50, calculateCellValue: function (value) { return (value.isValid ? 'Yes' : 'No'); } }),
                React.createElement(Column, { caption: "Version", dataField: "version", width: 100 }),
                React.createElement(Column, { caption: "Checklist Type", dataField: "templateType", width: 100 }),
                React.createElement(Column, { caption: "Org Unit", dataField: "orgUnitName", width: 200 }),
                React.createElement(Column, { caption: "Project Type", dataField: "projectType", width: 200 }),
                React.createElement(Column, { caption: "Description", dataField: "description", minWidth: 250 }))));
    };
    ChooseTemplateModal.prototype._getFooterButtons = function () {
        var onToggle = this.props.onToggle;
        return [
            {
                color: 'success',
                onClick: this._save,
                disabled: !this._chosenTemplate,
                title: 'Save'
            },
            {
                onClick: onToggle,
                title: 'Close'
            }
        ];
    };
    __decorate([
        observable
    ], ChooseTemplateModal.prototype, "_templates", void 0);
    __decorate([
        observable
    ], ChooseTemplateModal.prototype, "_chosenTemplate", void 0);
    __decorate([
        observable
    ], ChooseTemplateModal.prototype, "_loading", void 0);
    __decorate([
        action.bound
    ], ChooseTemplateModal.prototype, "_loadTemplates", null);
    __decorate([
        action.bound
    ], ChooseTemplateModal.prototype, "_save", null);
    __decorate([
        action.bound
    ], ChooseTemplateModal.prototype, "_onRadioChange", null);
    ChooseTemplateModal = __decorate([
        observer
    ], ChooseTemplateModal);
    return ChooseTemplateModal;
}(React.Component));
