var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { GateViewDescription } from './GateViewDescription/GateViewDescription';
import { Functions } from '../../Functions/Functions';
import { Approvals } from '../../Approvals/Approvals';
import { TgTabs } from '@app/Components/TgTabs/TgTabs';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { NavTab } from 'react-router-tabs';
import { TgTabsContent } from '@app/Components/TgTabs/TgTabsContent';
import { Checklists } from '../../Checklists/Checklists';
import { Api } from '@app/Api';
import { Loader } from '@app/Components/Loader/Loader';
import { Link } from '@app/Components/Link/Link';
import { Button } from '@app/Components/Button/Button';
import { Modal } from '@app/Components/Modal/Modal';
import { ProjectState as ProjectStateEnum } from '@app/AppConstants';
import { MeetingContent } from '@app/Widgets/Meeting/MeetingContent/MeetingContent';
import { ExternalApprovals } from '../../ExternalApprovals/ExternalApprovals';
var GateViewС = /** @class */ (function (_super) {
    __extends(GateViewС, _super);
    function GateViewС() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._gate = null;
        _this._isLoading = false;
        _this._isWarningOpen = false;
        _this._isWarningShowed = false;
        _this._checkWarning = function () {
            var _a, _b;
            var canPrincipalEditFunctions = _this.props.gate.canPrincipalEditFunctions;
            if (_this._isWarningShowed)
                return;
            var statuses = ((_a = _this._gate) === null || _a === void 0 ? void 0 : _a.setupStatus) !== 'Draft' && ((_b = _this._gate) === null || _b === void 0 ? void 0 : _b.setupStatus) !== 'InApproval';
            if (statuses && canPrincipalEditFunctions && !_this.props.isProjectTerminatedOrCompleted) {
                _this._toggleWarning();
            }
        };
        return _this;
    }
    GateViewС.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadGate()];
                    case 1:
                        _a.sent();
                        this._checkWarning();
                        return [2 /*return*/];
                }
            });
        });
    };
    GateViewС.prototype._loadGate = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._isLoading = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, , 3, 4]);
                        _a = this;
                        return [4 /*yield*/, Api.secured.getGate({ id: parseInt(this.props.match.params.id, 10) })];
                    case 2:
                        _a._gate = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    GateViewС.prototype._toggleWarning = function () {
        if (!this._isWarningShowed)
            this._isWarningShowed = true;
        this._isWarningOpen = !this._isWarningOpen;
    };
    GateViewС.prototype.getCustomButtons = function () {
        var _a = this.props, location = _a.location, match = _a.match, gate = _a.gate, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        var res = [];
        var canAdd = location.pathname.startsWith(match.url + '/approvals') &&
            gate.canPrincipalEditApprovals &&
            !isProjectTerminatedOrCompleted;
        if (canAdd)
            res.push(React.createElement(Link, { to: "".concat(match.url, "/approvals/add") },
                React.createElement(Button, { size: "sm", color: "success" }, "Add")));
        return res;
    };
    GateViewС.prototype.render = function () {
        var _a = this.props, projectId = _a.projectId, match = _a.match, gate = _a.gate, projectState = _a.projectState, isNorrdProject = _a.isNorrdProject, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        var canPrincipalEditFunctions = gate.canPrincipalEditFunctions, canPrincipalEditChecklists = gate.canPrincipalEditChecklists, canPrincipalEditApprovals = gate.canPrincipalEditApprovals;
        var isMilestoneProject = projectState === ProjectStateEnum.Milestone;
        if (this._isLoading) {
            return React.createElement(Loader, { style: { width: 100, height: 100 } });
        }
        if (!this._gate)
            return null;
        return (React.createElement(React.Fragment, null,
            React.createElement(React.Fragment, null,
                React.createElement(GateViewDescription, { gate: this._gate, onReloadGate: this._loadGate, isMilestoneProject: isMilestoneProject, isNorrdProject: isNorrdProject, editable: !isProjectTerminatedOrCompleted }),
                React.createElement(TgTabs, { startPathWith: match.url, customButtons: this.getCustomButtons() },
                    React.createElement(NavTab, { to: "/functions" }, "Functions"),
                    React.createElement(NavTab, { to: "/approvals" }, "Approvals"),
                    React.createElement(NavTab, { to: "/externalapprovals" }, "External Approvals"),
                    React.createElement(NavTab, { to: "/checklists" }, "Checklists"),
                    isMilestoneProject && React.createElement(NavTab, { to: "/milestone-meeting" }, "Milestone Meeting"),
                    !isMilestoneProject && React.createElement(NavTab, { to: "/gate-meeting" }, "Gate Meeting")),
                React.createElement(TgTabsContent, null,
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: "".concat(match.url, "/functions") },
                            React.createElement(Functions, { projectId: this.props.projectId, gate: this._gate, editable: canPrincipalEditFunctions && !isProjectTerminatedOrCompleted, isEnableDisableAllowed: isMilestoneProject, isNorrdProject: isNorrdProject })),
                        React.createElement(Route, { path: "".concat(match.url, "/approvals") },
                            React.createElement(Approvals, { editable: canPrincipalEditApprovals && !isProjectTerminatedOrCompleted, projectId: projectId, gateId: this._gate.id })),
                        React.createElement(Route, { path: "".concat(match.url, "/externalapprovals") },
                            React.createElement(ExternalApprovals, { editable: canPrincipalEditApprovals && !isProjectTerminatedOrCompleted, projectId: projectId, gateId: this._gate.id })),
                        React.createElement(Route, { path: "".concat(match.url, "/checklists") },
                            React.createElement(Checklists, { projectId: projectId, gateId: this._gate.id, editable: canPrincipalEditChecklists && !isProjectTerminatedOrCompleted })),
                        isMilestoneProject && (React.createElement(Route, { path: "".concat(match.url, "/milestone-meeting") },
                            React.createElement(MeetingContent, { projectId: projectId, gateId: this._gate.id, gateForecastOrActualDate: this._gate.forecastOrActualDate, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted }))),
                        !isMilestoneProject && (React.createElement(Route, { path: "".concat(match.url, "/gate-meeting") },
                            React.createElement(MeetingContent, { projectId: projectId, gateId: this._gate.id, gateForecastOrActualDate: this._gate.forecastOrActualDate, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted }))),
                        React.createElement(Route, { path: match.url },
                            React.createElement(Redirect, { to: "".concat(match.url, "/functions") }))))),
            React.createElement(Modal, { headerTitle: "Warning", isOpen: this._isWarningOpen, toggle: this._toggleWarning, footerbuttons: this._getFooterButtons() }, "Only continue if you are sure about changing the function representative.")));
    };
    GateViewС.prototype._getFooterButtons = function () {
        return [
            {
                color: 'success',
                onClick: this._toggleWarning,
                title: 'Continue'
            }
        ];
    };
    __decorate([
        observable
    ], GateViewС.prototype, "_gate", void 0);
    __decorate([
        observable
    ], GateViewС.prototype, "_isLoading", void 0);
    __decorate([
        observable
    ], GateViewС.prototype, "_isWarningOpen", void 0);
    __decorate([
        observable
    ], GateViewС.prototype, "_isWarningShowed", void 0);
    __decorate([
        action.bound
    ], GateViewС.prototype, "_loadGate", null);
    __decorate([
        action.bound
    ], GateViewС.prototype, "_toggleWarning", null);
    GateViewС = __decorate([
        observer
    ], GateViewС);
    return GateViewС;
}(React.PureComponent));
export var GateView = withRouter(GateViewС);
