var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Label } from 'reactstrap';
import { Input } from '@app/Components/Input/Input';
import { observer } from 'mobx-react';
import styles from './UpdateProjectGeneralInfoModal.module.scss';
import { observable, action } from 'mobx';
import { Api } from '@app/Api';
import { Modal } from '@app/Components/Modal/Modal';
import { DatePicker } from '@app/Components/DatePicker';
import { Select } from '@app/Components/Select/Select';
import { OrgUnitPicker } from '@app/Components/Pickers/OrgUnitPicker/OrgUnitPicker';
import { PersonPicker } from '@app/Components/Pickers/PersonPicker/PersonPicker';
var BooleanOptions = [
    { value: '0', label: 'No' },
    { value: '1', label: 'Yes' }
];
export var UpdateProjectGeneralInfoModal = /** @class */ (function (_super) {
    __extends(UpdateProjectGeneralInfoModal, _super);
    function UpdateProjectGeneralInfoModal(props) {
        var _this = _super.call(this, props) || this;
        _this._projectUpdateModel = props.projectUpdateModel;
        return _this;
    }
    UpdateProjectGeneralInfoModal.prototype._handleSaveClick = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.updateProjectGaneralInfo({ body: this._projectUpdateModel })];
                    case 1:
                        _a.sent();
                        this._toggleModal();
                        if (!this.props.onSave) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.props.onSave()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UpdateProjectGeneralInfoModal.prototype._handleUserSelect = function (value) {
        if (value && value[0]) {
            this._projectUpdateModel.leaderPrincipalId = value[0].id;
        }
    };
    UpdateProjectGeneralInfoModal.prototype._handleSecurityLevelChange = function (selectedOption) {
        if (!selectedOption || Array.isArray(selectedOption))
            return;
        var value = selectedOption.value;
        this._projectUpdateModel.securityLevel = value;
    };
    UpdateProjectGeneralInfoModal.prototype._handleCategoryChange = function (selectedOption) {
        if (!selectedOption || Array.isArray(selectedOption))
            return;
        var value = selectedOption.value;
        this._projectUpdateModel.category = value;
    };
    UpdateProjectGeneralInfoModal.prototype._updateOrgUnit = function (orgUnitId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._projectUpdateModel.responsibleOrgUnitId = parseInt(orgUnitId);
                return [2 /*return*/];
            });
        });
    };
    UpdateProjectGeneralInfoModal.prototype._handleIPKeyChange = function (selectedOption) {
        if (!selectedOption || Array.isArray(selectedOption))
            return;
        var value = selectedOption.value;
        this._projectUpdateModel.intellectualPropertyKeyProject = value === '1';
    };
    UpdateProjectGeneralInfoModal.prototype._handleDigitalChange = function (selectedOption) {
        if (!selectedOption || Array.isArray(selectedOption))
            return;
        var value = selectedOption.value;
        this._projectUpdateModel.isDigital = value === '1';
    };
    UpdateProjectGeneralInfoModal.prototype._toggleModal = function () {
        this.props.onToggle();
    };
    UpdateProjectGeneralInfoModal.prototype.render = function () {
        var _a;
        var _this = this;
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        var isOpen = (_a = this.props, _a.isOpen), securityLevels = _a.securityLevels, categories = _a.categories, leaderPrincipalModel = _a.leaderPrincipalModel;
        var isFormValid = !!(((_b = this._projectUpdateModel) === null || _b === void 0 ? void 0 : _b.displayName) &&
            ((_c = this._projectUpdateModel) === null || _c === void 0 ? void 0 : _c.responsibleOrgUnitId) &&
            ((_d = this._projectUpdateModel) === null || _d === void 0 ? void 0 : _d.leaderPrincipalId) &&
            ((_e = this._projectUpdateModel) === null || _e === void 0 ? void 0 : _e.plannedStartDate) &&
            ((_f = this._projectUpdateModel) === null || _f === void 0 ? void 0 : _f.plannedEndDate) &&
            ((_g = this._projectUpdateModel) === null || _g === void 0 ? void 0 : _g.securityLevel) &&
            ((_h = this._projectUpdateModel) === null || _h === void 0 ? void 0 : _h.category) &&
            ((_j = this._projectUpdateModel) === null || _j === void 0 ? void 0 : _j.totalBudget));
        return (React.createElement(Modal, { isOpen: isOpen, headerTitle: "Update Project", toggle: this._toggleModal, minWidth: '1000px', footerbuttons: this._getFooterButtons(isFormValid) },
            React.createElement("div", { className: styles.projectContainer },
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "Project Name"),
                        React.createElement(Input, { type: "text", defaultValue: (_k = this._projectUpdateModel) === null || _k === void 0 ? void 0 : _k.displayName, onChange: function (e) {
                                return (_this._projectUpdateModel.displayName = e.target.value);
                            } })),
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "Total Budget, KUSD"),
                        React.createElement(Input, { type: "number", defaultValue: (_l = this._projectUpdateModel) === null || _l === void 0 ? void 0 : _l.totalBudget, min: 0, onChange: function (e) {
                                return (_this._projectUpdateModel.totalBudget = parseFloat(e.target.value));
                            } }))),
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "Lead Responsible Org Unit"),
                        React.createElement(OrgUnitPicker, { defaultValue: (_m = this._projectUpdateModel) === null || _m === void 0 ? void 0 : _m.responsibleOrgUnitId.toString(), isForProject: true, isActiveOnly: true, isActiveOrWithProjectsOnly: false, onChangeHandler: function (ou) { return _this._updateOrgUnit(ou.value); } })),
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "Project Leader"),
                        React.createElement(PersonPicker, { defaultValue: leaderPrincipalModel, onUsersSelect: this._handleUserSelect }))),
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "Planned Start"),
                        React.createElement(DatePicker, { defaultValue: (_o = this._projectUpdateModel) === null || _o === void 0 ? void 0 : _o.plannedStartDate, onDateChanged: function (date) {
                                _this._projectUpdateModel.plannedStartDate = date;
                            }, max: (_p = this._projectUpdateModel) === null || _p === void 0 ? void 0 : _p.plannedEndDate, acceptCustomValue: false, type: "date" })),
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "Planned End"),
                        React.createElement(DatePicker, { defaultValue: (_q = this._projectUpdateModel) === null || _q === void 0 ? void 0 : _q.plannedEndDate, onDateChanged: function (date) {
                                _this._projectUpdateModel.plannedEndDate = date;
                            }, min: (_r = this._projectUpdateModel) === null || _r === void 0 ? void 0 : _r.plannedStartDate, acceptCustomValue: false, type: "date" }))),
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "Security Level"),
                        React.createElement(Select, { options: securityLevels, defaultValue: securityLevels === null || securityLevels === void 0 ? void 0 : securityLevels.find(function (x) { var _a; return x.value == ((_a = _this._projectUpdateModel) === null || _a === void 0 ? void 0 : _a.securityLevel); }), onChange: this._handleSecurityLevelChange })),
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "Project Category"),
                        React.createElement(Select, { options: categories, defaultValue: categories === null || categories === void 0 ? void 0 : categories.find(function (x) { var _a; return x.value == ((_a = _this._projectUpdateModel) === null || _a === void 0 ? void 0 : _a.category); }), onChange: this._handleCategoryChange }))),
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "IP Key"),
                        React.createElement(Select, { options: BooleanOptions, defaultValue: BooleanOptions.find(function (x) { var _a; return x.value === (((_a = _this._projectUpdateModel) === null || _a === void 0 ? void 0 : _a.intellectualPropertyKeyProject) ? '1' : '0'); }), onChange: this._handleIPKeyChange })),
                    React.createElement("div", { className: styles.halfField },
                        React.createElement(Label, null, "Digital"),
                        React.createElement(Select, { options: BooleanOptions, defaultValue: BooleanOptions.find(function (x) { var _a; return x.value === (((_a = _this._projectUpdateModel) === null || _a === void 0 ? void 0 : _a.isDigital) ? '1' : '0'); }), onChange: this._handleDigitalChange }))))));
    };
    UpdateProjectGeneralInfoModal.prototype._getFooterButtons = function (isFormValid) {
        return [
            {
                color: 'success',
                disabled: !isFormValid,
                onClick: this._handleSaveClick,
                title: 'Save'
            },
            {
                onClick: this._toggleModal,
                title: 'Close'
            }
        ];
    };
    __decorate([
        observable
    ], UpdateProjectGeneralInfoModal.prototype, "_projectUpdateModel", void 0);
    __decorate([
        action.bound
    ], UpdateProjectGeneralInfoModal.prototype, "_handleSaveClick", null);
    __decorate([
        action.bound
    ], UpdateProjectGeneralInfoModal.prototype, "_handleUserSelect", null);
    __decorate([
        action.bound
    ], UpdateProjectGeneralInfoModal.prototype, "_handleSecurityLevelChange", null);
    __decorate([
        action.bound
    ], UpdateProjectGeneralInfoModal.prototype, "_handleCategoryChange", null);
    __decorate([
        action.bound
    ], UpdateProjectGeneralInfoModal.prototype, "_updateOrgUnit", null);
    __decorate([
        action.bound
    ], UpdateProjectGeneralInfoModal.prototype, "_handleIPKeyChange", null);
    __decorate([
        action.bound
    ], UpdateProjectGeneralInfoModal.prototype, "_handleDigitalChange", null);
    __decorate([
        action.bound
    ], UpdateProjectGeneralInfoModal.prototype, "_toggleModal", null);
    UpdateProjectGeneralInfoModal = __decorate([
        observer
    ], UpdateProjectGeneralInfoModal);
    return UpdateProjectGeneralInfoModal;
}(React.PureComponent));
