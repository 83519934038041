var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Modal } from '@app/Components/Modal/Modal';
import DeliversTable from '@app/Pages/ProjectView/ProjectViewTabs/GateDeliverables/DeliversTable/DeliversTable';
var DocumentStatusModal = /** @class */ (function (_super) {
    __extends(DocumentStatusModal, _super);
    function DocumentStatusModal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DocumentStatusModal.prototype.render = function () {
        var _a = this.props, isOpen = _a.isOpen, toggle = _a.toggle, gateId = _a.gateId, projectId = _a.projectId, checklistItemId = _a.checklistItemId, checklistItemName = _a.checklistItemName, projectState = _a.projectState, hideNAItems = _a.hideNAItems, showRedDotDeliverable = _a.showRedDotDeliverable, isFilesChanged = _a.isFilesChanged, onDocumentIconClick = _a.onDocumentIconClick, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        return (React.createElement(React.Fragment, null,
            React.createElement(Modal, { isOpen: isOpen, toggle: toggle, minWidth: "1500px", headerTitle: "".concat(checklistItemName, " child deliverables") },
                React.createElement(DeliversTable, { gateId: gateId, projectId: projectId, checklistItemId: checklistItemId, onDocumentIconClick: onDocumentIconClick, projectState: projectState, hideNAItems: hideNAItems, showRedDotDeliverable: showRedDotDeliverable, isFilesChanged: isFilesChanged, isProjectTerminatedOrCompleted: isProjectTerminatedOrCompleted }))));
    };
    return DocumentStatusModal;
}(React.Component));
export { DocumentStatusModal };
