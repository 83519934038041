export var DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' };
export var DATE_WITH_TIME_OPTIONS = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
};
export var convertUTCDateToLocalDate = function (date) {
    var utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    return utcDate;
};
export var localDateToUTC = function (date) {
    var nowUtc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    return new Date(nowUtc);
};
export var getDateWithoutTime = function (date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCDay());
};
