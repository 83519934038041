var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Label } from 'reactstrap';
import { Input } from '@app/Components/Input/Input';
import styles from './AddGates.module.scss';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Api } from '@app/Api';
import { Loader } from '@app/Components/Loader/Loader';
import { DATE_OPTIONS, getDateWithoutTime } from '@app/Helpers/DateOptions';
import { DatePicker } from '@app/Components/DatePicker';
import { Modal } from '@app/Components/Modal/Modal';
import { Select } from '@app/Components/Select/Select';
import { RowLabel } from '@app/Components/FormControls';
import { HtmlEditor } from '@app/Components/HtmlEditor/HtmlEditor';
import { CompletionType, PromiseCompletion } from '@app/Classes/PromiseCompletion';
export var AddGates = /** @class */ (function (_super) {
    __extends(AddGates, _super);
    function AddGates() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._initGateName = '';
        _this._isSaved = new PromiseCompletion(CompletionType.Completed);
        _this._isSkipped = new PromiseCompletion(CompletionType.Completed);
        _this._htmlEditorRef = React.createRef();
        return _this;
    }
    Object.defineProperty(AddGates.prototype, "_hasNorrdGate", {
        get: function () {
            var _a, _b;
            return ((_a = this._data) === null || _a === void 0 ? void 0 : _a.norrdCompleted) !== undefined && ((_b = this._data) === null || _b === void 0 ? void 0 : _b.norrdCompleted) !== null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddGates.prototype, "_calculatedStartDate", {
        get: function () {
            if (this._data && this._data.forecastOrActualDate) {
                var calculated = new Date(this._data.forecastOrActualDate);
                calculated.setDate(calculated.getDate() - this._data.lengthInWeeks * 7);
                return calculated;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddGates.prototype, "_minCompletionTargetDate", {
        get: function () {
            var _a, _b, _c, _d;
            if ((_a = this._data) === null || _a === void 0 ? void 0 : _a.minimumAllowedStartDate) {
                var minDate = new Date((_b = this._data) === null || _b === void 0 ? void 0 : _b.minimumAllowedStartDate);
                minDate.setDate(((_c = this._data) === null || _c === void 0 ? void 0 : _c.minimumAllowedStartDate.getDate()) + ((_d = this._data) === null || _d === void 0 ? void 0 : _d.lengthInWeeks) * 7);
                return minDate;
            }
            else {
                return undefined;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddGates.prototype, "_maxDuration", {
        get: function () {
            var _a, _b;
            if (((_a = this._data) === null || _a === void 0 ? void 0 : _a.minimumAllowedStartDate) && ((_b = this._data) === null || _b === void 0 ? void 0 : _b.forecastOrActualDate)) {
                var diffDays = this._dateDiffInDays(this._data.minimumAllowedStartDate, this._data.forecastOrActualDate);
                return diffDays / 7;
            }
            else {
                return undefined;
            }
        },
        enumerable: false,
        configurable: true
    });
    AddGates.prototype._dateDiffInDays = function (a, b) {
        var _MS_PER_DAY = 1000 * 60 * 60 * 24;
        var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    };
    AddGates.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._initData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddGates.prototype.componentDidUpdate = function (prevProps) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(prevProps.gatesCount !== this.props.gatesCount)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._initData()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    AddGates.prototype._initData = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var projectId, previousGateId, _b;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        projectId = (_c = this.props, _c.projectId), previousGateId = _c.previousGateId;
                        _b = this;
                        return [4 /*yield*/, Api.secured.getGateInitData({ projectId: projectId, previousGateId: previousGateId })];
                    case 1:
                        _b._data = _d.sent();
                        this._initGateName = (_a = this._data.name) !== null && _a !== void 0 ? _a : '';
                        if (this._minCompletionTargetDate && !this._data.forecastOrActualDate && !this._hasNorrdGate) {
                            this._data.forecastOrActualDate = this._minCompletionTargetDate;
                        }
                        if (this._data.gateCombineOptions) {
                            this._gateCombineOptions = this._data.gateCombineOptions.map(function (x) { return ({
                                value: x.gateContainerNumber.toString(),
                                label: x.gateDisplayName || ''
                            }); });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AddGates.prototype._onToggle = function () {
        this.props.onToggle();
    };
    AddGates.prototype._onSave = function () {
        return __awaiter(this, void 0, void 0, function () {
            var promise;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this._data && this.props.onGateAdd)) return [3 /*break*/, 2];
                        promise = this.props.onGateAdd(this._data);
                        this._isSaved.subscribe(promise);
                        return [4 /*yield*/, promise];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this._onToggle();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddGates.prototype._onSkip = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var promise;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!this._data) return [3 /*break*/, 2];
                        promise = (_c = (_b = (_a = this.props).onGateSkip) === null || _b === void 0 ? void 0 : _b.call(_a, this._data)) !== null && _c !== void 0 ? _c : Promise.resolve();
                        this._isSkipped.subscribe(promise);
                        return [4 /*yield*/, promise];
                    case 1:
                        _d.sent();
                        _d.label = 2;
                    case 2:
                        this._onToggle();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddGates.prototype._handleTargetDateChange = function (date) {
        this._data.forecastOrActualDate = date;
    };
    AddGates.prototype._handleGateCombinationChange = function (selectedOption) {
        return __awaiter(this, void 0, void 0, function () {
            var value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (Array.isArray(selectedOption) || !this._data)
                            return [2 /*return*/];
                        if (selectedOption) {
                            value = parseInt(selectedOption.value);
                            this._data.combineGateContainerNumber = value;
                        }
                        else {
                            this._data.combineGateContainerNumber = undefined;
                        }
                        return [4 /*yield*/, this._refreshGateInfo()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddGates.prototype._refreshGateInfo = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var projectId, gateContainerNumber, combineGateContainerNumber, gateDescription, gateDefinition;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        projectId = this.props.projectId;
                        if (!this._data)
                            return [2 /*return*/];
                        gateContainerNumber = this._data.number;
                        combineGateContainerNumber = this._data.combineGateContainerNumber;
                        return [4 /*yield*/, Api.secured.getGateDescriptionData({
                                projectId: projectId,
                                gateContainerNumber: gateContainerNumber,
                                combineGateContainerNumber: combineGateContainerNumber ? combineGateContainerNumber : gateContainerNumber
                            })];
                    case 1:
                        gateDescription = (_b = _c.sent(), _b.gateDescription), gateDefinition = _b.gateDefinition;
                        this._data.gateDescription = gateDescription;
                        (_a = this._htmlEditorRef.current) === null || _a === void 0 ? void 0 : _a.reset(gateDescription);
                        this._data.gateDefinition = gateDefinition;
                        this._data.name = combineGateContainerNumber
                            ? "".concat(this._initGateName, "-").concat(combineGateContainerNumber)
                            : this._initGateName;
                        return [2 /*return*/];
                }
            });
        });
    };
    AddGates.prototype._handleNameChange = function (e) {
        var inputValue = e.target.value;
        var result = this._initGateName + inputValue.substring(this._initGateName.length, inputValue.length);
        this._data.name = result;
    };
    AddGates.prototype._handleGateDescriptionChange = function (e) {
        var _a;
        var parts = (_a = e.value) === null || _a === void 0 ? void 0 : _a.split('<p>');
        if (!parts)
            return (this._data.gateDescription = '');
        var isEmpty = true;
        for (var i = 0; i < parts.length; i++) {
            var p = parts[i];
            if (p.indexOf('</p>') !== -1) {
                p = p.replace('</p>', '');
            }
            if (p.length > 1) {
                // special chars in the end
                isEmpty = false;
                break;
            }
        }
        this._data.gateDescription = isEmpty ? '' : e.value;
    };
    AddGates.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var isMilestoneProject = this.props.isMilestoneProject;
        var isSaveDisabled = !(((_a = this._data) === null || _a === void 0 ? void 0 : _a.lengthInWeeks) &&
            ((_b = this._data) === null || _b === void 0 ? void 0 : _b.gateDescription) &&
            ((_c = this._data) === null || _c === void 0 ? void 0 : _c.name) &&
            ((_d = this._data) === null || _d === void 0 ? void 0 : _d.forecastOrActualDate));
        var isSkipDisabled = !!(!((_e = this._data) === null || _e === void 0 ? void 0 : _e.gateDescription) ||
            !((_f = this._data) === null || _f === void 0 ? void 0 : _f.name) ||
            ((_g = this._data) === null || _g === void 0 ? void 0 : _g.combineGateContainerNumber));
        return (React.createElement("div", { className: styles.container },
            React.createElement(Modal, { isOpen: this.props.isOpen, toggle: this._onToggle, headerTitle: isMilestoneProject ? 'Add Milestone' : 'Add Gate', maxWidth: "95%", footerbuttons: this._getFooterButtons(isSaveDisabled, isSkipDisabled) }, this._data ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.thirdField },
                        React.createElement(RowLabel, { text: "Name", className: styles.rowLabel, mandatory: true }),
                        React.createElement(Input, { placeholder: "Name", type: "text", value: this._data.name, maxLength: this._initGateName.length + 5, onChange: this._handleNameChange })),
                    this._gateCombineOptions && this._gateCombineOptions.length > 0 && (React.createElement("div", { className: styles.thirdField },
                        React.createElement(Label, null, isMilestoneProject ? 'Combine Milestones up to' : 'Combine Gates up to'),
                        React.createElement(Select, { isClearable: true, options: this._gateCombineOptions, onChange: this._handleGateCombinationChange })))),
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.field },
                        React.createElement(RowLabel, { text: isMilestoneProject ? 'Milestone Description' : 'Gate Description', className: styles.rowLabel, mandatory: true }),
                        React.createElement(HtmlEditor, { ref: this._htmlEditorRef, value: this._data.gateDescription, height: 180, onValueChanged: this._handleGateDescriptionChange }))),
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.field },
                        React.createElement(Label, null, isMilestoneProject ? 'Milestone Definition' : 'Gate Definition'),
                        React.createElement(Input, { placeholder: isMilestoneProject ? 'Milestone Definition' : 'Gate Definition', type: "textarea", rows: 8, value: this._data.gateDefinition, onChange: function (e) {
                                return (_this._data.gateDefinition = e.target.value);
                            } }))),
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.field },
                        React.createElement(Label, null, "Scope Limitation"),
                        React.createElement(Input, { type: "text", value: (_h = this._data.scopeLimitation) !== null && _h !== void 0 ? _h : '', onChange: function (e) {
                                return (_this._data.scopeLimitation = e.target.value);
                            } }))),
                React.createElement("div", { className: styles.line },
                    React.createElement("div", { className: styles.thirdField },
                        React.createElement(RowLabel, { text: "Forecast Or Actual Date", className: styles.rowLabel, mandatory: true }),
                        React.createElement(DatePicker, { disabled: !this._data.isForecastOrActualDateEditable, onDateChanged: this._handleTargetDateChange, className: styles.datePicker, value: this._data.forecastOrActualDate, acceptCustomValue: false, type: "date" })),
                    React.createElement("div", { className: styles.thirdField },
                        React.createElement(RowLabel, { text: "Duration In Weeks", className: styles.rowLabel, mandatory: true }),
                        React.createElement(Input, { placeholder: "Duration in weeks", type: "number", min: 1, value: this._data.lengthInWeeks, max: this._maxDuration, onChange: function (e) {
                                return (_this._data.lengthInWeeks = parseInt(e.target.value, 10));
                            } })),
                    this._calculatedStartDate && (React.createElement("div", { className: styles.thirdField },
                        React.createElement(Label, null, "Calculated Start Date"),
                        React.createElement(Input, { placeholder: "Date", type: "text", value: getDateWithoutTime(this._calculatedStartDate).toLocaleDateString('en-GB', DATE_OPTIONS), disabled: true })))),
                this._hasNorrdGate && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles.line },
                        React.createElement("div", { className: styles.field },
                            React.createElement(Label, null, "Inherited Access From NORRD"),
                            React.createElement(Input, { type: "text", value: this._data.norrdGateAssessors, disabled: true, onChange: function (e) {
                                    return (_this._data.norrdGateAssessors =
                                        e.target.value);
                                } }))),
                    React.createElement("div", { className: styles.line },
                        React.createElement(Label, null,
                            "NORRD Gate Status:",
                            this._data.norrdCompleted ? 'COMPLETED' : 'NOT COMPLETED')))))) : (React.createElement(Loader, { style: { width: 100, height: 100 } })))));
    };
    AddGates.prototype._getFooterButtons = function (isSaveDisabled, isSkipDisabled) {
        return [
            {
                color: 'success',
                onClick: this._onSkip,
                disabled: isSkipDisabled || this._isSaved.isPending,
                isLoading: this._isSkipped.isPending,
                title: 'Skip'
            },
            {
                color: 'success',
                onClick: this._onSave,
                disabled: isSaveDisabled || this._isSkipped.isPending,
                isLoading: this._isSaved.isPending,
                title: 'Save'
            },
            {
                onClick: this._onToggle,
                title: 'Close'
            }
        ];
    };
    __decorate([
        observable
    ], AddGates.prototype, "_data", void 0);
    __decorate([
        observable
    ], AddGates.prototype, "_initGateName", void 0);
    __decorate([
        observable
    ], AddGates.prototype, "_gateCombineOptions", void 0);
    __decorate([
        observable
    ], AddGates.prototype, "_isSaved", void 0);
    __decorate([
        observable
    ], AddGates.prototype, "_isSkipped", void 0);
    __decorate([
        observable
    ], AddGates.prototype, "_htmlEditorRef", void 0);
    __decorate([
        computed
    ], AddGates.prototype, "_hasNorrdGate", null);
    __decorate([
        computed
    ], AddGates.prototype, "_calculatedStartDate", null);
    __decorate([
        computed
    ], AddGates.prototype, "_minCompletionTargetDate", null);
    __decorate([
        computed
    ], AddGates.prototype, "_maxDuration", null);
    __decorate([
        action.bound
    ], AddGates.prototype, "_initData", null);
    __decorate([
        action.bound
    ], AddGates.prototype, "_onToggle", null);
    __decorate([
        action.bound
    ], AddGates.prototype, "_onSave", null);
    __decorate([
        action.bound
    ], AddGates.prototype, "_onSkip", null);
    __decorate([
        action.bound
    ], AddGates.prototype, "_handleTargetDateChange", null);
    __decorate([
        action.bound
    ], AddGates.prototype, "_handleGateCombinationChange", null);
    __decorate([
        action.bound
    ], AddGates.prototype, "_refreshGateInfo", null);
    __decorate([
        action.bound
    ], AddGates.prototype, "_handleNameChange", null);
    __decorate([
        action.bound
    ], AddGates.prototype, "_handleGateDescriptionChange", null);
    AddGates = __decorate([
        observer
    ], AddGates);
    return AddGates;
}(React.PureComponent));
