var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Grid } from '@app/Components/Grid/Grid';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Column } from 'devextreme-react/data-grid';
import styles from './ChecklistView.module.scss';
import { Api } from '@app/Api';
import HTMLReactParser from 'html-react-parser';
export var CheckListItemsSimpleTable = /** @class */ (function (_super) {
    __extends(CheckListItemsSimpleTable, _super);
    function CheckListItemsSimpleTable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._items = [];
        _this._loading = false;
        return _this;
    }
    CheckListItemsSimpleTable.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadItems()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CheckListItemsSimpleTable.prototype._loadItems = function () {
        return __awaiter(this, void 0, void 0, function () {
            var isDeliverables, options, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        isDeliverables = this.props.isDeliverables;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, , 6, 7]);
                        options = {
                            checkListId: this.props.checklistId,
                            isApplicableForTemplate: this.props.isApplicableForTemplate
                        };
                        this._loading = true;
                        _a = this;
                        if (!isDeliverables) return [3 /*break*/, 3];
                        return [4 /*yield*/, Api.secured.getDeliverableChecklistItems(options)];
                    case 2:
                        _b = _c.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, Api.secured.getQuestionChecklistItems(options)];
                    case 4:
                        _b = _c.sent();
                        _c.label = 5;
                    case 5:
                        _a._items = _b;
                        return [3 /*break*/, 7];
                    case 6:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    CheckListItemsSimpleTable.prototype.render = function () {
        var isDeliverables = this.props.isDeliverables;
        return (React.createElement(Grid, { dataSource: this._items, rowsCount: this._items.length, loading: this._loading, expandableRows: true },
            React.createElement(Column, { caption: "ID", dataField: "code", width: 100 }),
            React.createElement(Column, { caption: "Function", dataField: "functionNames", width: 150, cellRender: function (_a) {
                    var _b;
                    var data = _a.data;
                    return (React.createElement("div", null, (_b = data.functionNames) === null || _b === void 0 ? void 0 : _b.map(function (f) { return (React.createElement("div", { key: f.id, className: "".concat(styles.function, " ").concat(f.isResponsibleInputProvider ? styles.bold : '') }, f.name)); })));
                } }),
            isDeliverables && React.createElement(Column, { caption: "Key Deliverables", dataField: "deliverableText", width: 400 }),
            !isDeliverables && (React.createElement(Column, { caption: "Question", dataField: "questionTitle", width: 400, cellRender: function (_a) {
                    var data = _a.data;
                    return (React.createElement("div", null,
                        data.questionTitle && (React.createElement("div", { className: styles.questionTitle }, data.questionTitle)),
                        React.createElement("div", null, HTMLReactParser(data.question || ''))));
                } })),
            React.createElement(Column, { caption: "Guideline", dataField: "guideline", minWidth: 400, cellRender: function (_a) {
                    var data = _a.data;
                    return (React.createElement("div", { style: { whiteSpace: 'pre-line' } }, HTMLReactParser(data.guideline || '')));
                } })));
    };
    __decorate([
        observable
    ], CheckListItemsSimpleTable.prototype, "_items", void 0);
    __decorate([
        observable
    ], CheckListItemsSimpleTable.prototype, "_loading", void 0);
    __decorate([
        action
    ], CheckListItemsSimpleTable.prototype, "_loadItems", null);
    CheckListItemsSimpleTable = __decorate([
        observer
    ], CheckListItemsSimpleTable);
    return CheckListItemsSimpleTable;
}(React.PureComponent));
